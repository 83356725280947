import Cookies from "universal-cookie";
import { LOGIN, LOGOUT, DOMAIN } from "../constant";

const cookies = new Cookies();
let curUrl = DOMAIN;

const INITIAL_STATE = {
  currentUser: null,
};

export default function loginReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        currentUser: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem("auth_data");
      localStorage.removeItem("fromUrl");
      localStorage.removeItem("validOrgs");
      localStorage.removeItem("profile_data");
      cookies.remove("currentUser", { path: "/", domain: `${curUrl}` });
      cookies.remove("access-token", { path: "/", domain: `${curUrl}` });
      cookies.remove("uid", { path: "/", domain: `${curUrl}` });
      cookies.remove("client", { path: "/", domain: `${curUrl}` });
      cookies.remove("expires", { path: "/", domain: `${curUrl}` });
      return {
        ...state,
        currentUser: null,
      };
    default:
      return state;
  }
}
