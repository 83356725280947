import React, { Component } from "react";

class FooterSmall extends Component {
  render() {
    return (
      <div className="footer">
        <p className="footer-text">© {new Date().getFullYear()} Awign Enterprises Private Limited</p>
      </div>
    );
  }
}

export default FooterSmall;
