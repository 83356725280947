import { DOMAIN, LOGGY_URL } from "../constant";
import { session_id } from "../components/shared/custom_html_elements/utils";
import customHttp from "../custom_http";
import Cookies from "universal-cookie";

export const pushLoggyEvent = (event_data) => {
  const cookies = new Cookies();
  const url = LOGGY_URL;
  let user = cookies.get("currentUser", { path: "/", domain: `${DOMAIN}` });
  user = user?.data?.data?.user;
  // user = user ? JSON.parse(user) : "";
  const user_id = user?.id || "N/A";
  const user_role = user?.roles?.[0] || "N/A";
  const data = {
    user_id,
    user_role,
    ...event_data,
    session_id: session_id(),
    origin: "office_ui",
    application_name: "office_ui",
    event_at: new Date().toJSON(),
  };
  const request = customHttp({
    url,
    data,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return request;
};
