import React, { useEffect } from "react";
import { FULFILLMENT_URL } from "../../../constant";

const RedirectScreen = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.assign(`${FULFILLMENT_URL}${window.location.pathname}${window.location.search}`);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-vh-100 center-x-y">
      <div className="text-center">
        <div className="text-center">
          <img src="https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/logo-icon-blue.png" alt="icon" height="50px" />
        </div>
        <div className="center-x my-3">
          <div className="custom_loading">
            <div></div>
          </div>
        </div>
        <h5>You are being redirected to {<a href={FULFILLMENT_URL}>fulfillment platform</a>}, please wait....</h5>
        <h5 className="mb-4">{`If you aren't being redirected, click on the below link`}</h5>
        <a href={FULFILLMENT_URL}>Continue</a>
      </div>
    </div>
  );
};

export default RedirectScreen;
