import React, { Component } from "react";
import { connect } from "react-redux";
import { logout, getOrg } from "../../../actions";
import history from "../../../history";
import { isEmpty } from "../../../components/shared/custom_html_elements/utils";
import { Navbar, Nav, NavDropdown, Modal } from "react-bootstrap";
//import { Link } from "react-router-dom";
import { LOGO_NAME_WHITE, WEBSITE_URL } from "../../../constant";
import { withCustomGetScreen } from "../../../utils/customGetScreen";
import Notifications from "../notifications";
import { headerTitles } from "./constants";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      hasConfigurationAccessFor: "",
    };
  }

  openUrl = (link) => {
    window.location.assign(WEBSITE_URL + "/" + link);
  };

  callLogout = () => {
    this.props.logout().then(() => history.push("/"));
  };

  handleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const awign_permissions = this.props.currentUser?.permissions?.awign || [];
    const hideExploreSection = awign_permissions?.includes("hide_explore_section");
    if (this.props.currentUser) {
      return !this.props.isMobile() ? (
        <div>
          <div className="full-width bg-blue fixed-top">
            <div className="row">
              <div className="col-md-11 offset-md-1">
                <div className="row center-y justify-content-between">
                  <div className="col-6 col-md-8 center-y">
                    <div className="d-inline-block pointer relative float-left pr-5" onClick={() => window.location.assign(WEBSITE_URL)}>
                      <img src={LOGO_NAME_WHITE} className="full-width full-height header-img relative" alt="awign.com" />
                    </div>
                    <div className="tabList white relative float-left">
                      <div onClick={() => this.openUrl("office")} className="QuickLinks active-quicklink header-items pointer float-left font-14px">
                        Office
                      </div>
                      {!hideExploreSection && (
                        <div onClick={() => this.openUrl("categories")} className="QuickLinks header-items pointer float-left font-14px">
                          Explore
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6 col-md-3 d-flex">
                    <Notifications isMobile={this.props.isMobile} />
                    <Navbar.Toggle className="justify-content-end float-right" id="basic-navbar-nav">
                      <Nav className="mr-right">
                        <NavDropdown
                          title={
                            <span>
                              <img
                                className="header-icon-profile"
                                onMouseOver={(e) => (e.currentTarget.src = "https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/topbar/ic_profile_blue.svg")}
                                onMouseLeave={(e) => (e.currentTarget.src = "https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/ic_profile.svg")}
                                src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/ic_profile.svg"
                              />
                              <small className="text-white">{this.props.currentUser.name}</small>
                            </span>
                          }
                          id="profile-nav-dropdown"
                          className="left-menu with-header"
                        >
                          <NavDropdown.Item onClick={() => this.callLogout()} eventKey={3.2}>
                            <img className="dropdown-icons" src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/svgs/ic_logout.svg" /> Logout
                          </NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
                    </Navbar.Toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobile-layout-wrapper d-flex">
          {
            <span onClick={() => history.goBack()}>
              <img src="https://awign-production.s3.ap-south-1.amazonaws.com/office-ui/go_back.svg" className="mr-2" />
            </span>
          }
          <div className="pointer d-inline" onClick={() => history.push("/choose_access")}>
            {headerTitles[this.props.match.path]}
          </div>
          <div className="ml-auto d-flex">
            <div className="mr-4">
              <Notifications isMobile={this.props.isMobile} />
            </div>
            <div className="mr-3" onClick={this.handleModal}>
              <img src="https://awign-production.s3.ap-south-1.amazonaws.com/office-ui/logout_icon.svg" alt="logout" width="21px" height="24px" />
            </div>
          </div>
          {this.state.showModal && (
            <Modal show={true} onHide={() => {}}>
              <Modal.Body className="p-4">
                <h6 className="black">Are you sure you want to logout of Awign Office ?</h6>
                <div className="mt-4">
                  <button className="btn btn-primary mr-3 ml-auto font-14" onClick={this.callLogout}>
                    Logout
                  </button>
                  <button className="btn btn-white font-14 black" onClick={this.handleModal}>
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.login.currentUser,
    deviceType: state.common.deviceType,
    project: state.projects.project,
  };
}

export default connect(mapStateToProps, { logout, getOrg })(withCustomGetScreen(Header));
