import { SIGNATURE_DETAILS, AADHAR_DETAILS, DL_DETAILS } from "../constant";

const INITIAL_STATE = {
  signature_details: { val: { name: "", mobile_number: "", address: "", pincode: "", city: "", state: "", latitude: "", longitude: "", font: "" }, signature_id: "" },
  aadhar_details: { aadhar_number: "", aadhar_front_image: "", aadhar_back_image: "" },
  dl_details: {
    driving_licence_type: "",
    driving_licence_vehicle_types: [],
    driving_licence_number: "",
    driving_licence_validity: { date: "", month: "", year: "" },
    driving_licence_data: { front_image: "", back_image: "" },
  },
};

export default function OfferLetterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNATURE_DETAILS:
      return { ...state, signature_details: action.payload };
    case AADHAR_DETAILS:
      return { ...state, aadhar_details: action.payload };
    case DL_DETAILS:
      return { ...state, dl_details: action.payload };
    default:
      return state;
  }
}
