import { CAS_API_URL, GLOBAL_ERROR, IH_OMS_API_URL, PROJECT, ORGANISATION, WOS_API_URL, LEAD_ATTRIBUTES, ERROR_500, ATTENDANCE } from "../constant";
import customHttp from "../custom_http";
import { handleLogoutError } from "./action_common";

export function searchProjects(data) {
  const url = `${IH_OMS_API_URL}/config/api/v1/projects/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getProject(id) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: PROJECT,
          payload: response.data.data.project,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getOrg(id) {
  const url = `${CAS_API_URL}/api/v1/orgs/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: ORGANISATION,
          payload: response.data.data.org,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchSummaryViews(execution_id, role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/summary_views/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function summaryViewAnalyze(execution_id, project_role_id, access_type, tab_id, summary_view_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/project_owner/executions/${execution_id}/project_roles/${project_role_id}/tabs/${tab_id}/summary_views/${summary_view_id}/analyze`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchManagerAnalyze(execution_id, project_role_id, summary_view_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/project_owner/executions/${execution_id}/project_roles/${project_role_id}/summary_views/${summary_view_id}/analyze`;

  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function listViewsSearch(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchScreens(data, project_id, role_id, data_view_id) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_roles/${role_id}/data_views/${data_view_id}/screens/search`;

  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function leadsAnalyzeSearch(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/project_roles/${project_role_id}/leads/analyze`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchLeadAttributes(id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${id}/lead_attributes/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: LEAD_ATTRIBUTES,
          payload: response.data.data,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function asyncReportsSearch(execution_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/background_requests/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchRoles(project_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_roles/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getUnauthenticatedDataView(project_id, project_role_id, data_view_id) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${project_role_id}/data_views/${data_view_id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getUnauthenticatedDataViewScreenSearch(data, project_id, project_role_id, data_view_id) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${project_role_id}/data_views/${data_view_id}/screens/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getUnauthenticatedLead(project_id, role_id, lead_id) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${role_id}/leads/${lead_id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function unauthenticatedNextScreen(project_id, role_id, data_view_id, lead_id, screen_id) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${role_id}/data_views/${data_view_id}/leads/${lead_id}/screens/${screen_id}/next`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function createUnauthenticatedLead(data, project_id, role_id) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${role_id}/leads`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateUnauthenticatedLead(data, project_id, role_id, lead_id) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${role_id}/leads/${lead_id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateUnauthenticatedLeadStatus(data, project_id, role_id, lead_id) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${role_id}/leads/${lead_id}/status`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateUnauthenticatedLeadUidAttribute(data, project_id, role_id, lead_id, lead_attribute_uid) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${role_id}/leads/${lead_id}/attributes/${lead_attribute_uid}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function leadPreview(execution_id, project_role_id, data_view_id, lead_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/data_views/${data_view_id}/leads/${lead_id}/preview`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function unauthenticatedPreview(project_id, project_role_id, data_view_id, lead_id) {
  const url = `${IH_OMS_API_URL}/unauthenticated_api/v1/projects/${project_id}/project_roles/${project_role_id}/data_views/${data_view_id}/leads/${lead_id}/preview`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function summaryViewBlocksAnalyzeWorkforce(execution_id, project_role_id, access_type, list_view_id, summary_view_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/${access_type}/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/summary_views/${summary_view_id}/blocks/analyze`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchLocations(data) {
  const url = `${WOS_API_URL}/api/v1/locations/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchProjectReports(project_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_reports/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function createProjectReport(project_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_reports`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Created Successfully",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function updateProjectReport(project_id, project_report_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_reports/${project_report_id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Updated Successfully",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function deleteProjectReport(project_id, project_report_id) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_reports/${project_report_id}`;
  const request = customHttp({
    url,
    method: "delete",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "Deleted Successfully",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export const getApplicationResources = (user_id, application_id) => {
  const url = `${WOS_API_URL}/api/v1/supplies/${user_id}/applications/${application_id}/resources`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
};

export function requestWork(execution_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/request_work`;
  const request = customHttp({
    url,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getMyJobs(category_uid, supply_id) {
  const reqBody = {
    conditions: [
      {
        status: { operator: "not_in", value: ["created", "selected", "backed_out", "execution_started", "execution_completed", "approved_to_work"] },
        supply_pending_action: { operator: "not", value: "explore_other_jobs" },
      },
    ],
    page: 1,
    limit: 100,
    skip_saas_org_id: true,
  };

  const url = `${WOS_API_URL}/api/v2/categories/${category_uid}/supplies/${supply_id}/applications/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    data: reqBody,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function getWorklogConfigs(execution_id, project_role_id) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${execution_id}/project_roles/${project_role_id}/worklog_configs/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchWorklogConfigs(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/worklogs/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    data,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}
export function searchAttendancePunches(data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/attendance_punches/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    data,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: ATTENDANCE, payload: response.data });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateWorklogConfigs(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/worklogs`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    data,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateAttendance(execution_id, attendance_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/attendance_punches/${attendance_id}`;
  const request = customHttp({
    url,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    data,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}
