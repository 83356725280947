import React from "react";
import moment from "moment";
import * as Yup from "yup";

export const windowWidth = () => {
  if (window.innerWidth <= 768) {
    return "Smartphone";
  }
  return "Desktop";
};

export const capitalizeFirstLetter = (inputString = "") => {
  if (inputString === null) {
    return null;
  }
  return inputString.replace(/_/g, " ").replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

export const incrementalString = (inputString) => {
  let sequence = inputString.replace(/[^0-9]/g, "");
  if (sequence === "") {
    return inputString + "1";
  }
  return inputString.replace(/[0-9]/g, "") + (parseInt(sequence) + 1).toString();
};

export const timeConverter = (time) => {
  let hours;
  let minutes;
  hours = new Date(moment(time)).getHours();
  minutes = new Date(moment(time)).getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  } else if (minutes === 0) {
    minutes = "00";
  }
  if (parseInt(hours) === 12) {
    return `${hours}:${minutes} PM`;
  }
  if (parseInt(hours) >= 13) {
    hours -= 12;
    return `${hours}:${minutes} PM`;
  }
  return `${hours}:${minutes} AM`;
};

export const isEmpty = (obj) => {
  // THIS EMPTY CHECK DOESN'T VALIDATE NUMBERS. KEEP IN MIND
  //var hasOwnProperty = Object.prototype.hasOwnProperty;
  // null and undefined are "empty"
  // eslint-disable-next-line
  if (obj == null) return true;
  if (obj === (undefined || "undefined")) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  if (typeof obj === "number") return false;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  if (obj instanceof Date) return false;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const reject = (obj, keys) => {
  return Object.keys(obj)
    .filter((k) => !keys.includes(k))
    .map((k) => Object.assign({}, { [k]: obj[k] }))
    .reduce((res, o) => Object.assign(res, o), {});
};

export const accessObj = (t, obj) => {
  t = t.replace(/\[/g, ".");
  t = t.replace(/\]/g, "");
  t = t.split(".");
  let c = obj;
  for (var i = 0; i < t.length; i++) {
    try {
      c = c[t[i]];
    } catch (e) {
      return "";
    }
  }
  return c;
};

export const isEmptyArrayValue = (val) => {
  return Array.isArray(val) && val[0] === "";
};

export const rejectParams = (obj, keys) => {
  return reject(obj, keys);
};

export const getKeyByValue = (object, value, remove = []) => {
  return Object.keys(object)
    .filter((role) => !remove.includes(role))
    .find((key) => object[key] === value);
};

export const getPOLevelRoles = (project_roles) => {
  let po_level_roles = [];
  project_roles.forEach((role) => {
    if (role.group_name === "Project Owner") {
      po_level_roles = [...po_level_roles, role.name];
    }
  });
  return po_level_roles;
};

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      removeEmpty(obj[key]);
    } else if (obj[key] === undefined || obj[key] === "" || obj[key] === null || obj[key].length === 0) {
      delete obj[key];
    }
  });
  return obj;
};

export const getRenderTypeIcon = (render_type) => {
  switch (render_type) {
    case "file":
      return "files.svg";
    case "image":
      return "images.svg";
    case "audio":
      return "audio.svg";
    case "video":
      return "video.svg";
    case "pdf":
      return "files.svg";
    case "url":
      return "urlImg.svg";

    case "single_select_radio":
      return "boolean.svg";
    case "date":
      return "dateImg.svg";
    case "date_time":
      return "dateTime.svg";
    case "time":
      return "timeImg.svg";

    case "datetime_range":
      return "dateTime.svg";
    case "date_range":
      return "dateImg.svg";
    case "time_range":
      return "timeImg.svg";

    case "email":
      return "emailImg.svg";

    case "current_location":
      return "currentLocation.svg";
    case "geo_address":
      return "geoLocation.svg";

    case "multi_select_dropdown":
      return "multiAndSingleSelectDropDown.svg";
    case "multi_select_checkbox":
      return "multiAndSingleSelectDropDown.svg";

    case "integer":
      return "number.svg";
    case "float":
      return "number.svg";

    case "nested":
      return "nested.svg";
    case "phone":
      return "phone.svg";
    case "phone_bridge":
      return "phone.svg";

    case "pincode":
      return "pinCode.svg";

    case "single_select_dropdown":
      return "multiAndSingleSelectDropDown.svg";
    case "single_select_radi":
      return "multiAndSingleSelectDropDown.svg";
    case "single_select_decision_button":
      return "decisionButton.svg";

    case "signature":
      return "images.svg";
    case "single_select_image":
      return "images.svg";
    case "multi_select_image":
      return "images.svg";

    case "short_text":
      return "text.svg";
    case "long_text":
      return "text.svg";

    default:
      return "text.svg";
  }
};

export const monthConverter = (month) => {
  switch (month) {
    case 1:
      return "Jan";

    case 2:
      return "Feb";

    case 3:
      return "Mar";

    case 4:
      return "Apr";

    case 5:
      return "May";

    case 6:
      return "June";

    case 7:
      return "July";

    case 8:
      return "Aug";

    case 9:
      return "Sep";

    case 10:
      return "Oct";

    case 11:
      return "Nov";

    case 12:
      return "Dec";

    default:
      return month;
  }
};

export const dateConverter = (time) => {
  const curDay = moment(time).format("dddd");
  const curYear = new Date(moment(time)).getFullYear();
  const curMonth = monthConverter(new Date(moment(time)).getMonth() + 1);
  const curDate = new Date(moment(time)).getDate();

  return `${curDay}, ${curDate} ${curMonth}, ${curYear}`;
};

export const dateFormatter = (date) => `${date.getUTCFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const timeFormatter = (time) => `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;

export const dateTimeParser = (dateTime) => {
  const day = new Date(moment(dateTime));
  return day;
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const stringDeparameterize = (str1) => {
  return str1.trim().replace(/-/g, " ");
};

export const stringParameterize = (str1) => {
  return str1
    .trim()
    .replace(/[^a-zA-Z0-9 -]/, "")
    .replace(/\s/g, "-");
};

export const randomNumber = (min, max) => {
  let random = Math.floor(Math.random() * (+max - +min)) + +min;
  return random;
};

export const truncateString = (str, length) => {
  if (str === undefined) {
    return "";
  }
  if (str.length > length) {
    return str.substring(0, length) + "...";
  } else {
    return str;
  }
};

export const randomStringGenerator = (length) =>
  Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
    .toString(36)
    .slice(1);

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const operatorMap = [
  { value: "eq", label: "Equals to" },
  { value: "neq", label: "Not Equal to" },
  { value: "contains", label: "Contains" },
  { value: "not_contains", label: "Does Not Contains  " },
  { value: "is_empty", label: "Is Empty" },
  { value: "not_empty", label: "Not Empty" },
  { value: "starts_with", label: "Starts With" },
  { value: "ends_with", label: "Ends With" },
  { value: "between", label: "Between" },
  { value: "includes", label: "Includes" },
  { value: "previous", label: "Previous" },
  { value: "next", label: "Next" },
  { value: "current", label: "Current" },
  { value: "lt", label: "Before" },
  { value: "gt", label: "After" },
  { value: "before", label: "Before" },
  { value: "after", label: "After" },
  { value: "on", label: "On" },
];

export const allOperators = {
  text: [
    { value: "eq", label: "Equals to" },
    { value: "neq", label: "Not Equal to" },
    { value: "contains", label: "Contains" },
    { value: "not_contains", label: "Does Not Contains  " },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
    { value: "starts_with", label: "Starts With" },
    { value: "ends_with", label: "Ends With" },
    { value: "previous", label: "Previous" },
    { value: "next", label: "Next" },
    { value: "current", label: "Current" },
    { value: "before", label: "Before" },
    { value: "after", label: "After" },
    { value: "on", label: "On" },
  ],
  email: [
    { value: "eq", label: "Equals to" },
    { value: "neq", label: "Not Equal to" },
    { value: "contains", label: "Contains" },
    { value: "not_contains", label: "Does Not Contains  " },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
    { value: "starts_with", label: "Starts With" },
    { value: "ends_with", label: "Ends With" },
    { value: "previous", label: "Previous" },
  ],
  number: [
    { value: "eq", label: "Equals to" },
    { value: "gt", label: "Greater than" },
    { value: "gte", label: "Greater than or equal to" },
    { value: "lt", label: "Less than" },
    { value: "lte", label: "Less than equal to" },
    { value: "neq", label: "Not Equal to" },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
    { value: "between", label: "Between" },
  ],
  single_select: [
    { value: "eq", label: "Equals to" },
    { value: "neq", label: "Not Equal to" },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
    { value: "includes", label: "Includes" },
  ],
  multi_select: [
    { value: "includes", label: "Includes" },
    { value: "is_empty", label: "Is Empty" },
    { value: "not_empty", label: "Not Empty" },
  ],
  date_time: [
    { value: "previous", label: "Previous" },
    { value: "next", label: "Next" },
    { value: "current", label: "Current" },
    { value: "before", label: "Before" },
    { value: "after", label: "After" },
    { value: "on", label: "On" },
    { value: "between", label: "Between" },
    { value: "is_empty", label: "Empty" },
    { value: "not_empty", label: "Not Empty" },
  ],
  datetime_range: [
    { value: "between", label: "Between" },
    { value: "is_empty", label: "Empty" },
    { value: "not_empty", label: "Not Empty" },
  ],
};

export const timeOptions = [
  { value: "minutes", label: "minutes" },
  { value: "hours", label: "hours" },
  { value: "days", label: "days" },
  { value: "weeks", label: "weeks" },
  { value: "months", label: "months" },
  { value: "years", label: "years" },
];

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
}

export const getYupString = (renderType, required, character_format, character_length) => {
  let validate;
  switch (renderType) {
    case "string":
      validate = Yup.string().max(200, "Max length should be less than 200 characters");
      break;
    case "short_text":
      validate = Yup.string().max(200, "Max length should be less than 200 characters");
      break;
    case "code_scanner":
      validate = Yup.string();
      if (character_format === "number") {
        validate = validate.matches(/^[0-9]+$/i, "Please enter only numeric characters.");
      } else if (character_format === "alphanumeric") {
        validate = validate.matches(/^[a-z0-9]+$/i, "Please ensure your input contains both letters and numbers.");
      }
      if (character_length) {
        validate = validate.trim().length(character_length, `Should be ${character_length} characters long`);
      }
      break;
    case "text":
      validate = Yup.string().max(2000, "Max length should be less than 2000 characters");
      break;
    case "long_text":
      validate = Yup.string().max(2000, "Max length should be less than 2000 characters");
      break;
    case "email":
      validate = Yup.string().email("Enter a valid email");
      break;
    case "float":
      validate = Yup.number("Enter a valid number").transform(emptyStringToNull);
      break;
    case "integer":
      validate = Yup.number("Enter a valid number")
        //.positive("Enter positive number")
        .test("int", "Must be integer", (val) => {
          return val % 1 === 0 || val === "" || val === undefined;
        })
        .transform(emptyStringToNull);
      break;
    case "phone":
      validate = required ? Yup.string().matches(/^[6-9]\d{9}$/, "Phone number is not valid") : Yup.string();
      break;
    case "url":
      validate = Yup.string().url("Enter Valid URL");
      break;
    case "time":
      validate = Yup.string().nullable();
      break;
    case "time_range":
    case "date_range":
    case "datetime_range":
      validate = Yup.array().of(Yup.string().required("Required").nullable()).nullable();
      break;
    default:
      validate = Yup.string();
  }

  if (renderType === "single_select_dropdown" && required) {
    validate = Yup.object()
      .shape({ value: Yup.string().required("Required").nullable() })
      .required("Required")
      .nullable();
  } else if (required) {
    validate = validate.required("Required").nullable();
  } else {
    validate = validate.nullable();
  }
  return validate;
};

export const getImg = (type) => {
  // console.log("the type will be", type);
  let img =
    type === "string"
      ? "ic_short_text.svg"
      : type === "text"
      ? "ic_long_text.svg"
      : type === "email"
      ? "ic_email.svg"
      : type === "phone"
      ? "ic_phone.svg"
      : type === "number" || type === "integer" || type === "float"
      ? ".svg"
      : "ic_long_text.svg";
  return img;
};

export const deepCopyFunction = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null || inObject instanceof Date) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = typeof value === "object" && value !== null ? deepCopyFunction(value) : value;
  }

  return outObject;
};

export const removeEmptyinArray = (arr) => {
  return arr.filter(function (e) {
    return e === 0 || e;
  });
};

const getTimeFormat = (date_time_format) => {
  let timeFormat = dateTimeFormats[date_time_format]?.type;
  if (!isEmpty(timeFormat)) {
    timeFormat = timeFormat === "HH:mm" ? "HH:mm:00" : timeFormat === "mm:ss" ? "00:mm:ss" : timeFormat;
  } else {
    timeFormat = "HH:mm:00";
  }
  return timeFormat;
};

export const formatValue = (value, render_type, date_time_format = "") => {
  // Handle array type for all render type
  if (!isEmpty(value)) {
    if (render_type === "date") {
      if (Array.isArray(value)) {
        return removeEmptyinArray(value).map((n) => (n instanceof Date ? momentFormikDateFormatter(n) : n));
      } else {
        let date = value instanceof Date ? momentFormikDateTimeFormatter(value) : value;
        return date;
      }
    } else if (render_type === "date_time") {
      if (Array.isArray(value)) {
        return removeEmptyinArray(value).map((n) => (n instanceof Date ? momentFormikDateTimeFormatter(n) : n));
      } else {
        let datetime = value instanceof Date ? momentFormikDateTimeFormatter(value) : value;
        return datetime;
      }
    } else if (render_type === "datetime_range") {
      return value;
    } else if (render_type === "single_select_dropdown") {
      return value.value;
    } else if (render_type === "multi_select_dropdown" && !isEmpty(value)) {
      let values = typeof value === "string" ? [value] : (value || []).map((item) => (item["value"] ? item["value"] : item));
      return values;
    } else if (render_type === "time") {
      let timeFormat = getTimeFormat(date_time_format);
      if (Array.isArray(value)) {
        return removeEmptyinArray(value).map((n) => (n instanceof Date ? `${moment(n).format(timeFormat)}` : n));
      } else {
        let time = value instanceof Date ? `${moment(value).format(timeFormat)}` : value;
        return time;
      }
    } else if (render_type === "time_range") {
      let timeFormat = getTimeFormat(date_time_format);
      let startTime = value[0] instanceof Date ? `${moment(value[0]).format(timeFormat)}` : value[0];
      let endTime = value[1] instanceof Date ? `${moment(value[1]).format(timeFormat)}` : value[1];
      var timeRange = [startTime, endTime];
      return timeRange;
    } else if (["integer", "float"].includes(render_type)) {
      if (Array.isArray(value)) {
        return removeEmptyinArray(value).map((n) => Number(n));
      } else {
        return Number(value);
      }
    }
    return value;
  } else {
    return null;
  }
};

export const getFileFormat = (type) => {
  // const formatObj = {image: "image/*",audio:".mp3,.wav,.ogg,.ogv",video:"video/*",pdf:".pdf",signature:"image/*"};
  let format =
    type === "image" ? "image/*" : type === "audio" ? ".mp3,.wav,.ogg,.ogv" : type === "video" ? "video/*" : type === "pdf" ? ".pdf" : type === "signature" ? "image/*" : "*";
  return format;
};

export const parseValues = (value, type = null) => {
  if (isEmpty(value)) {
    return null;
  }
  if (["date", "date_time", "time", "current_location"].includes(type)) {
    if (type === "time") {
      return <span>{momentTimeFormatter(value)}</span>;
    } else if (type === "date") {
      return <span>{momentDateFormatter(value)}</span>;
    } else if (type === "date_time") {
      return <span>{momentDateTimeFormatter(value)}</span>;
    } else {
      return <span>{value.toString()}</span>;
    }
  }

  if (Array.isArray(value)) {
    return (
      <div>
        {value.map(function (value, value_index) {
          if (["time_range", "date_range", "datetime_range"].includes(type)) {
            if (type === "time_range") {
              return (
                <span className="array-badge" key={value_index}>
                  {momentTimeFormatter(value)}
                </span>
              );
            } else if (type === "date_range") {
              return (
                <span className="array-badge" key={value_index}>
                  {momentDateFormatter(value)}
                </span>
              );
            } else if (type === "datetime_range") {
              return (
                <span className="array-badge" key={value_index}>
                  {momentDateTimeFormatter(value)}
                </span>
              );
            } else {
              return (
                <span className="array-badge" key={value_index}>
                  {value.toString()}
                </span>
              );
            }
          } else {
            return (
              <span className="array-badge word-break-all" key={value_index}>
                {value}
              </span>
            );
          }
        })}
      </div>
    );
  } else if (typeof value === "object") {
    const keys = Object.keys(value);
    if (!isEmpty(keys)) {
      return (
        <div>
          {keys.map(function (key, index) {
            return (
              <div key={index} className="pr-2">
                <span className="bold">{key}:</span> {value[key]}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  } else {
    return <span className="word-break-all">{value}</span>;
  }
};

export const generateQuery = (query) => {
  const keys = Object.keys(query);
  const val = Object.values(query);
  const test = val.map((elm, index) =>
    typeof elm === "string" || typeof elm === "number"
      ? `${keys[index]}=${elm}`
      : Object.entries(elm).map((el) => {
          if (Array.isArray(el[1])) {
            const arr = el[1].map((v) => (isNaN(v) ? v.replace(/,/g, "%2C") : v));
            return [el[0], arr.join(":::")].join("=");
          } else {
            return el.join("=");
          }
        })
  );
  // Joining keys with operator and value
  const test2 = test.map((elm, index) => {
    if (Array.isArray(elm)) {
      return elm.map((el) => keys[index] + "::" + el).join("&");
    } else {
      return elm;
    }
  });
  const final_query = test2.join("&");
  return final_query;
};

export const parseQuery = (search_term) => {
  const keys_arr = [];
  const parseObj = {};
  const query = search_term.slice(1);
  if (isEmpty(query)) return {};
  const part1 = query.split("&");
  // Separating Non Filters Conditions from query
  const raw_non_filter_conditions = part1.filter((elm) => !elm.match(/:::|::/));
  const non_filter_conditions_array = raw_non_filter_conditions.map((elm) => elm.split("="));
  const non_filter_conditions_obj = Object.fromEntries(non_filter_conditions_array);
  // Separting Filters Conditions from query
  const raw_filter_conditions = part1.filter((elm) => elm.match(/:::|::/));
  // Replacing array values separator with comma
  const raw_filter_conditions_1 = raw_filter_conditions.map((elm) => elm.replace(/:::/g, ","));
  // Spliting keys with operator and value
  const raw_filter_conditions_2 = raw_filter_conditions_1.map((elm) => elm.split("::"));
  // Pushing filters conditions keys to keys_arr and assigning filters conditions keys to parseObj
  raw_filter_conditions_2.map((elm) => {
    if (!keys_arr.includes(elm[0])) {
      keys_arr.push(elm[0]);
      parseObj[elm[0]] = [];
    }
  });
  // Splitting values of operator and value constants
  const raw_filter_conditions_3 = raw_filter_conditions_2.map((elm) => [elm[0], elm[1].split("=")]);
  // Combining operator array and value array of same key into single array
  raw_filter_conditions_3.map((elm) => (parseObj[elm[0]] = [...parseObj[elm[0]], elm[1]]));
  // Creating object from operator and values array
  keys_arr.map((elm) => (parseObj[elm] = Object.fromEntries(parseObj[elm])));
  // Creating array of values for in and between operator
  keys_arr.map((elm) => {
    if (elm.includes("_assigned") && isEmpty(parseObj[elm]["value"])) {
      parseObj[elm]["value"] = null;
    }
    if (["in", "between"].includes(parseObj[elm]["operator"])) {
      const arr = parseObj[elm]["value"].split(",");
      const arr2 = arr.map((val) => decodeURIComponent(val));
      parseObj[elm]["value"] = arr2;
    }
  });
  const final = { ...non_filter_conditions_obj, payload: { ...parseObj } };
  return final;
};

export const debounce = function (fun, delay) {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fun.apply(context, args);
    }, delay);
  };
};

export default function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function checkIfMongoId(id) {
  if (id.match(/^[0-9a-fA-F]{24}$/)) {
    return true;
  } else {
    return false;
  }
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const executionStatuses = [
  // { value: "added", label: "Added" },
  { value: "approved", label: "Approved" },
  { value: "started", label: "Started" },
  { value: "duration_extended", label: "Duration Extended" },
  { value: "submitted", label: "Submitted" },
  { value: "completed", label: "Completed" },
  { value: "halted", label: "Halted" },
  { value: "rejected", label: "Rejected" },
  { value: "backed_out", label: "Backed Out" },
  { value: "disqualified", label: "Disqualified" },
  { value: "blacklisted", label: "Blacklisted" },
];

export const allocationHeaders = [
  { uid: "execution", column_title: "Execution Id" },
  { uid: "member", column_title: "Member Id" },
];

export function copy() {
  /* Get the text field */
  var copyText = document.getElementById("copy-field");
  /* Select the text field */
  copyText.select();
  /* Copy the text inside the text field */
  document.execCommand("copy");
  /* Alert the copied text */
  console.log(copyText.value);
}

const levelHierarchy = {
  UnAuthenticated: 5,
  Workforce: 1,
  Manager: 2,
  "Project Owner": 3,
  Client: 4,
};

export function filterProjectLevels(projectLevels, level) {
  return projectLevels.filter((pl) => levelHierarchy[pl.name] <= levelHierarchy[level]);
}

export function filterProjectRoles(projectRoles) {
  let roles_to_exclude = ["Anonymous"];
  return projectRoles.filter((pr) => !roles_to_exclude.includes(pr.name));
}

export function momentDateFormatter(value, format) {
  if (isEmpty(value)) {
    return value;
  }
  const date_format = dateTimeFormats[format];
  const default_format = "DD/MM/YYYY";
  return moment(value, "YYYY-MM-DD HH:mm:ss").format(date_format ? date_format : default_format);
}

export function momentTimeFormatter(value, format) {
  //return moment(value, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
  // let formattedTime = !isEmpty(value) ? value.split(":").slice(0, -1).join(":") : value;
  return value;
}

export function momentDateTimeFormatter(value, format) {
  if (isEmpty(value)) {
    return value;
  }
  const date_time_format = dateTimeFormats[format];
  const default_format = "DD/MM/YYYY hh:mm A";
  return moment(value).format(date_time_format ? date_time_format : default_format);
}

export function momentFormikDateFormatter(value) {
  if (isEmpty(value)) {
    return value;
  }
  return moment(value, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
}

export function momentFormikDateTimeFormatter(value) {
  if (isEmpty(value)) {
    return value;
  }
  return moment(value, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
}

export const isValidPincode = (value) => {
  return /^[1-9][0-9]{5}$/.test(value);
};

export const isValidDate = (value) => {
  if (isEmpty(value)) {
    return false;
  }
  return moment(value).isValid();
};

export const isValidNumber = (value) => {
  return /^\d+$/.test(value);
};

export const isValidMobileNumber = (value) => /^[6-9]\d{9}$/.test(value);

export const isValidSentence = (value) => {
  return /^[a-zA-Z]+$/.test(value);
};

export const getUpdateQueryString = (url, removableStr) => {
  let queryElements = url.split("&");
  let updatedQueryElements = [];
  if (!isEmpty(queryElements)) {
    queryElements.map(function (el) {
      if (!el.includes(removableStr)) {
        updatedQueryElements.push(el);
      }
      return null;
    });
    return updatedQueryElements.join("&");
  }
  return url;
};

export const verticalOptions = [
  { label: "Invigilation", value: "invigilation" },
  { label: "Proctoring", value: "proctoring" },
  { label: "Business Development", value: "business_development" },
  { label: "Due Diligence", value: "due_diligence" },
  { label: "Digital Gig", value: "digital_gig" },
  { label: "Last Mile Delivery", value: "last_mile_delivery" },
  { label: "Auditing", value: "auditing" },
];

export const session_id_generator = () => {
  return "xxxxxxxxxxxx4xxx".replace(/x/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const session_id = () => {
  const storedId = sessionStorage.getItem("session_id");
  if (storedId) return storedId;
  const sessionId = session_id_generator();
  sessionStorage.setItem("session_id", sessionId);
  return sessionId;
};

export const dateTimeFormats = {
  "%H:%M": { type: "HH:mm:00", views: ["hours", "minutes"] },
  "%H:%M:%S": { type: "HH:mm:ss", views: ["hours", "minutes", "seconds"] },
  "%M:%S": { type: "00:mm:ss", views: ["minutes", "seconds"] },
  "%d/%m/%Y": "DD/MM/YYYY",
  "%d/%m/%Y %H:%M": "DD/MM/YYYY hh:mm A",
  "%d/%m/%Y %H:%M:%S": "DD/MM/YYYY hh:mm:ss A",
};

export const isUrlChanged = (prevProps, props) => {
  const prevParams = prevProps.match.params;
  const currentParams = props.match.params;
  const isProjectIdChanged = prevParams.project_id !== currentParams.project_id;
  const isExecutionIdChanged = prevParams.execution_id !== currentParams.execution_id;
  const isRoleNameChanged = prevParams.role_name !== currentParams.role_name;
  return isProjectIdChanged || isExecutionIdChanged || isRoleNameChanged;
};

const isRequired = (type) => {
  throw Error(`${type} is missing in arguments`);
};

export const downloadPdf = (url = isRequired("url"), name = isRequired("file_name")) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
};
