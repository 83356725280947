import { combineReducers } from "redux";
import commonReducer from "./reducer_common";
import loginReducer from "./reducer_auth";
import ProjectsReducer from "./reducer_projects";
import ExecutionsReducer from "./reducer_executions";
import errorReducer from "./reducer_error";
import ProfileReducer from "./reducer_profile";
import WorkforceAnalyseReducer from "./reducer_workforce_analyse";
import OfferLetterReducer from "./reducer_offer_letter";
import NotificationsReducer from "./reducer_notifications";
import AttendanceReducer from "./reducer_attendance";

const rootReducer = combineReducers({
  common: commonReducer,
  login: loginReducer,
  projects: ProjectsReducer,
  execution: ExecutionsReducer,
  my_profile: ProfileReducer,
  error: errorReducer,
  workforce_analyse_state: WorkforceAnalyseReducer,
  offer_letter: OfferLetterReducer,
  notifications: NotificationsReducer,
  attendance: AttendanceReducer,
});

export default rootReducer;
