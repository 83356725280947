import React, { useState } from "react";
import { connect } from "react-redux";
import { updateSingleNotification, getAllNotifications } from "../../../../actions";
import { getCurrentDateDifference } from "./constants";
import NotifModal from "./notifModal";

const notifNormalStyle = { borderBottom: "solid 1px #ecf3f5", backgroundColor: "rgba(17, 61, 188, 0.1)", wordBreak: "break-word" };
const notifAckedStyle = { borderBottom: "solid 1px #ecf3f5", backgroundColor: "white", wordBreak: "break-word" };

export const SingleNotification = ({ notification, updateSingleNotification, getAllNotifications, isMobile, handleBellIcon }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClickNotification = () => {
    setShowModal(true);
    if (notification.status !== "acked") {
      const data = {
        notification: {
          status: "acked",
        },
      };
      updateSingleNotification(data, notification.id).then((returnValue) => {
        if (returnValue?.status === "successStatus") {
          getAllNotifications();
        } else {
          return;
        }
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <div
        className="d-flex py-3 px-5 pointer"
        style={notification?.status === "acked" ? notifAckedStyle : notifNormalStyle}
        onClick={handleClickNotification}
        data-notificationstab="true"
      >
        <img
          className="mr-3"
          src="https://awign-production.s3.ap-south-1.amazonaws.com/office-ui/notif_logo.svg"
          alt="logo"
          style={{ height: "40px" }}
          data-notificationstab="true"
        />
        <div data-notificationstab="true">
          <p data-notificationstab="true" className="font-16 black semi-bold ">
            {notification?.title}
          </p>
          <p data-notificationstab="true" className="font-14" style={{ color: "#3c4858" }}>
            {notification?.notification_text}
          </p>
          <p data-notificationstab="true" className="mb-0 gray font-12">
            {getCurrentDateDifference(notification?.created_at)}
          </p>
        </div>
      </div>
      {showModal && <NotifModal handleBellIcon={handleBellIcon} isMobile={isMobile} notification={notification} closeModal={closeModal} />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  updateSingleNotification,
  getAllNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleNotification);
