import React, { memo } from "react";
import NotificationContent from "./NotificationContent";
import "./notifications.css";

const Mobile = memo((props) => {
  return (
    <div>
      <div className="bg-blue white px-5 py-5">
        <span className="font-20 pointer" onClick={props.handleBellIcon}>
          x
        </span>
        <p className="font-24">Notification</p>
      </div>
      <div className={`bg-white pt-4 mobile_content`}>
        <NotificationContent handleBellIcon={props.handleBellIcon} isMobile={props.isMobile} notifications={props.notifications} />
      </div>
    </div>
  );
});

export default Mobile;
