import React, { Component, lazy } from "react";
import { isEmpty, stringDeparameterize } from "./components/shared/custom_html_elements/utils";
import { searchExecutions, searchRoles, searchRoleExecutions, setExecution } from "./actions";
import Header from "./components/shared/header/";
import Breadcrumbs from "./components/shared/breadcrumbs";
import FooterSmall from "./components/shared/footer_small/footer_small";
import { withCustomGetScreen } from "./utils/customGetScreen";
const Sidebar = lazy(() => import("./components/shared/sidebar"));
import Loader from "./components/shared/custom_html_elements/loader";
import { RestrictedProject } from "./components/shared/restriction";
import { connect } from "react-redux";

export function layoutWrapper(WrappedComponent, showHeader = true, showFooter = true, shadeBackground = false, showBreadcrumb = false, showSidebar = false, pageName) {
  class PP extends Component {
    state = { loading: false, restrict: false, execution_data: {} };
    componentDidMount() {
      if (!isEmpty(this.props.currentUser)) {
        this.handleAccess();
      }
    }
    componentDidUpdate(prevProps) {
      if (isEmpty(prevProps.currentUser) && !isEmpty(this.props.currentUser)) {
        this.handleAccess();
      }
    }

    handleRestriction = (returnValue) => {
      const { match } = this.props;
      const { params } = match;
      if (returnValue?.status === "successStatus") {
        const execution = returnValue.message.data.data.executions[0];
        if (isEmpty(execution)) {
          this.setState({ restrict: true, loading: false });
        } else if (!isEmpty(execution) && execution._id !== params.execution_id) {
          let { path } = match;
          Object.keys(params).forEach((param) => {
            path = path.replace(`:${param}`, param === "execution_id" ? execution._id : params[param]);
          });
          const url = `${location.origin}${path}${location.search}`;
          window.open(url, "_self");
        } else {
          this.props.setExecution(execution);
          this.setState({ loading: false, execution_data: execution });
        }
      }
    };

    handleExecutionSearch = (role) => {
      const { match, currentUser, searchExecutions } = this.props;
      const { params } = match;
      const member_id = currentUser.ih_oms_id;
      const access_type_payload = {
        type: { operator: "eq", value: "WorkforceExecution" },
        project_roles: { operator: "eq", value: role },
      };
      const payload = { conditions: [{ ...access_type_payload, project_id: { operator: "eq", value: params.project_id } }], skip_saas_org_id: true };
      searchExecutions(member_id, params.access_type, payload).then(this.handleRestriction);
    };

    handleAccess = () => {
      const { match, currentUser, searchRoleExecutions, searchRoles } = this.props;
      const { params } = match;
      const roles = isEmpty(currentUser) ? [] : currentUser.roles;
      if (!(roles || []).includes("int-product-support") && params?.project_id && params?.execution_id) {
        this.setState({ loading: true });
        const member_id = currentUser.ih_oms_id;
        if (params.role_name) {
          let role = stringDeparameterize(params.role_name);
          const data = { conditions: [{ uid: { operator: "eq", value: role } }, { name: { operator: "eq", value: role } }], limit: 50, skip_saas_org_id: true };
          searchRoles(params.project_id, data).then((response) => {
            if (response?.status === "successStatus") {
              const project_role = response.message.data.data.project_roles[0];
              if (isEmpty(project_role)) {
                this.setState({ restrict: true, loading: false });
              } else {
                role = project_role.name;
                this.handleExecutionSearch(role);
              }
            } else {
              this.setState({ restrict: true, loading: false });
            }
          });
        } else {
          const access_type_payload = { type: { operator: "eq", value: "WorkforceExecution" } };
          const payload = { conditions: [{ ...access_type_payload, member_id: { operator: "eq", value: member_id } }] };
          searchRoleExecutions(params.project_id, params.role_id, payload).then(this.handleRestriction);
        }
      }
    };

    render() {
      const isMobile = this.props.isMobile();
      const { params } = this.props.match;
      const { currentUser, ...rest } = this.props;
      const { loading, restrict, execution_data } = this.state;
      const wrapperFunction = () => (
        <div className={`${isMobile ? "" : pageName === "ScreensLeads" ? "" : "layout_wrapper "} ${shadeBackground ? "shade-background" : ""}`}>
          {showHeader && !(isMobile && pageName === "ScreensLeads") && <Header {...rest} />}
          {!restrict && !isMobile && showBreadcrumb && <Breadcrumbs {...rest} />}
          {!restrict && !isMobile && showSidebar && <Sidebar {...rest} />}
          {loading ? <Loader /> : restrict ? <RestrictedProject params={params} isMobile={isMobile} /> : <WrappedComponent {...rest} execution_data={execution_data} />}
          {showFooter && <FooterSmall />}
        </div>
      );
      return wrapperFunction();
    }
  }

  const mapStateToProps = ({ login: { currentUser } }) => ({ currentUser });
  return connect(mapStateToProps, { searchExecutions, searchRoleExecutions, searchRoles, setExecution })(withCustomGetScreen(PP));
}
