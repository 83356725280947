import {
  GLOBAL_ERROR,
  EXECUTION,
  GLOBAL_SUCCESS,
  CAS_API_URL,
  IH_OMS_API_URL,
  GLOBAL_INFO,
  WORKFORCE_ANALYSE_EXECUTIONS,
  DATA_VIEWS,
  LEAD_FILTERS,
  PAYOUT_GROUPS,
  ERROR_500,
  DOCUMENTS_URL,
} from "../constant";
import customHttp from "../custom_http";
import { handleLogoutError } from "./action_common";

export function searchExecutions(id, access_type, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/members/${id}/executions/${access_type}/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchRoleExecutions(id, role_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${id}/project_roles/${role_id}/executions/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: EXECUTION,
          payload: response.data.data.executions[0],
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        dispatch({ type: EXECUTION, payload: null });
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchProjectLevelExecutions(id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${id}/executions/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchConfigExecutions(project_id, data) {
  const url = `${IH_OMS_API_URL}/config/api/v1/projects/${project_id}/executions/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getExecution(id) {
  const url = `${IH_OMS_API_URL}/execution/api/v1/executions/${id}`;
  return (dispatch) =>
    customHttp({
      url,
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        dispatch({
          type: EXECUTION,
          payload: response.data.data.execution,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getMember(id) {
  const url = `${IH_OMS_API_URL}/execution/api/v1/members/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchProjectRoleGroups(data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/project_role_groups/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchLeads(execution_id, role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchDataViews(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/data_views/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: DATA_VIEWS,
          payload: response.data.data,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function patchLead(execution_id, screen_id, lead_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/screens/${screen_id}/leads/${lead_id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchNextScreen(execution_id, screen_id, lead_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/leads/${lead_id}/screens/${screen_id}/next`;
  const request = customHttp({
    url,
    method: "get",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateStatus(execution_id, project_role_id, screen_id, lead_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/screens/${screen_id}/leads/${lead_id}/status`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function bulkStatusUpdate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/bulk_status_update`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload:
            "Your request to change status of the leads has been taken successfully. Please refresh after sometime to see the updated leads or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getLead(execution_id, role_id, lead_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/leads/${lead_id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function addLead(execution_id, role_id, list_view_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/list_views/${list_view_id}/leads`;

  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function cloneLead(execution_id, role_id, list_view_id, lead_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${role_id}/list_views/${list_view_id}/leads/${lead_id}/clone`;

  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: GLOBAL_SUCCESS, payload: "Cloned Successfully" });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function deleteLead(execution_id, project_role_id, list_view_id, lead_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/leads/${lead_id}`;

  const request = customHttp({
    url,
    method: "delete",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

//lead attribute update
export function updateLeadUidAttribute(execution_id, list_view_id, lead_id, lead_attribute_uid, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/list_views/${list_view_id}/leads/${lead_id}/attributes/${lead_attribute_uid}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function bulkLeadsCreate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/csv_create`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload: "Your request to create leads has been taken successfully. Please refresh after few minutes to see the newly created leads.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function bulkLeadsUpdate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/csv_update`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload:
            "Your request to update leads has been taken successfully. Please refresh after sometime to see the updated leads or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function bulkLeadsAllocate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/bulk_allocate`;

  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload:
            "Your request to allocate leads has been taken successfully. Please refresh after sometime to see the allocated leads or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function exportLeads(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/export`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: GLOBAL_INFO, payload: "Your request to export has been taken successfully. Please check the reports section for the exported leads." });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function bulkFieldsUpdate(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/bulk_update`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload:
            "Your request to update fields has been taken successfully. Please refresh after sometime to see the updated leads or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function bulkEmail(project_id, data) {
  const url = `${IH_OMS_API_URL}/execution/api/v1/projects/${project_id}/executions/bulk_email`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: GLOBAL_INFO, payload: "Your request to send email has been taken successfully. Please check reports section for the status of your request." });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function bulkSms(project_id, data) {
  const url = `${IH_OMS_API_URL}/execution/api/v1/projects/${project_id}/executions/bulk_sms`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: GLOBAL_INFO, payload: "Your request to send sms has been taken successfully Please check reports section for the status of your request." });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getUser(memberId) {
  const url = `${CAS_API_URL}/client/api/v1/users/${memberId}`; //TODO: Check API
  return (dispatch) =>
    customHttp({
      url,
      method: "get",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        dispatch({
          type: EXECUTION,
          payload: response.data.data.execution,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchHooks(project_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/hooks/search`;
  return (dispatch) =>
    customHttp({
      url,
      method: "post",
      data,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function bulkTriggers(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/bulk_trigger`;
  return (dispatch) =>
    customHttp({
      url,
      method: "post",
      data,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function requestTranscribe(execution_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/leads/transcribe_request`;

  return (dispatch) =>
    customHttp({
      url,
      method: "post",
      data,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        dispatch({ type: GLOBAL_SUCCESS, payload: "Your request is taken successfully" });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function requestDigitize(execution_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/leads/digitize`;
  return (dispatch) =>
    customHttp({
      url,
      method: "post",
      data,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        dispatch({ type: GLOBAL_SUCCESS, payload: "Your request is taken successfully" });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function executionsAnalyze(project_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/executions/analyze`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function executionsSearch(project_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/executions/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function executionsSmartSearch(project_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/project_roles/${project_role_id}/executions/smart_search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: WORKFORCE_ANALYSE_EXECUTIONS,
          payload: response.data.data,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function filterView(execution_id, project_role_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/project_roles/${project_role_id}/filter_view`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: LEAD_FILTERS,
          payload: response.data.data,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchPayoutGroup(project_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_roles/${project_role_id}/payout_groups/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: PAYOUT_GROUPS,
          payload: response.data.data,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateExecution(project_id, execution_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/project_owner/projects/${project_id}/executions/${execution_id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch, getState) =>
    request
      .then((response) => {
        const state = getState();
        const executions_state = state.workforce_analyse_state.executions;
        const old_executions_data = executions_state?.executions;
        const updated_execution = response.data.data.execution;
        const new_executions_data = old_executions_data.map((elm) => (elm._id === updated_execution._id ? updated_execution : elm));
        const new_response = { ...executions_state, executions: [...new_executions_data] };
        dispatch({ type: WORKFORCE_ANALYSE_EXECUTIONS, payload: new_response });
        dispatch({ type: GLOBAL_SUCCESS, payload: "Updated" });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateExecutionStatus(project_id, execution_id, _status) {
  const data = { execution: { _status } };
  const url = `${IH_OMS_API_URL}/office/api/v1/project_owner/projects/${project_id}/executions/${execution_id}/status_update`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch, getState) =>
    request
      .then((response) => {
        if (_status === "certificate_requested") {
          dispatch({ type: GLOBAL_INFO, payload: "Your request to issue certificate has been taken successfully. Certificate will be dispatched to your mail shortly." });
        } else {
          const state = getState();
          const executions_state = state.workforce_analyse_state.executions;
          const old_executions_data = executions_state?.executions;
          const updated_execution = response.data.data.execution;
          const new_executions_data = old_executions_data.map((elm) => (elm._id === updated_execution._id ? updated_execution : elm));
          const new_response = { ...executions_state, executions: [...new_executions_data] };
          dispatch({ type: WORKFORCE_ANALYSE_EXECUTIONS, payload: new_response });
          dispatch({ type: GLOBAL_SUCCESS, payload: "Updated" });
        }
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getUserData(id) {
  const url = `${CAS_API_URL}/profile/api/v1/users/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        }
      });
}

export function updateProfile(data, id) {
  const url = `${CAS_API_URL}/profile/api/v1/users/${id}`;
  const request = customHttp({
    url,
    data,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        }
      });
}

export function updateAadhar(data, id) {
  const url = `${CAS_API_URL}/profile/api/v1/users/${id}/aadhar_details`;
  const request = customHttp({
    url,
    data,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        }
      });
}

export function parseAadhar(data) {
  const url = `${DOCUMENTS_URL}/images/parse_aadhar`;
  const request = customHttp({
    url,
    data,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        }
      });
}

export function verifyAadharOtp(data, user_id) {
  const url = `${CAS_API_URL}/profile/api/v1/users/${user_id}/aadhar_details/verify_otp`;
  const request = customHttp({
    url,
    data,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        }
      });
}

export function updateDrivingLicence(data, id) {
  const url = `${CAS_API_URL}/profile/api/v1/users/${id}/driving_licence_details`;
  const request = customHttp({
    url,
    data,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        }
      });
}

export function addAddress(data, id) {
  const url = `${CAS_API_URL}/profile/api/v1/users/${id}/addresses`;
  const request = customHttp({
    url,
    data,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        }
      });
}

export function updateAddress(data, id, address_id) {
  const url = `${CAS_API_URL}/profile/api/v1/users/${id}/addresses/${address_id}`;
  const request = customHttp({
    url,
    data,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        }
      });
}

export function searchUsers(data) {
  const url = `${CAS_API_URL}/central/api/v1/users/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_SUCCESS,
          payload: "",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function exportExecutions(project_id, role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/project_roles/${role_id}/executions/export`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload:
            "Your request to export the workforce has been taken successfully. Please refresh after sometime to see the updated leads or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getLeadHistory(execution_id, lead_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/leads/${lead_id}/history?limit=500&page=1`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function exportAllLeads(execution_id, project_role_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/leads/export_all`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({ type: GLOBAL_INFO, payload: "Your request to export has been taken successfully. Please check the reports section for the exported leads." });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getExecutionDetails(id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/members/${id}/executions/workforce/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getTaskTabs(execution_id, project_role_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/tabs/analyze`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getListView(execution_id, project_role_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function getFaqs(execution_id, project_role_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/faq_view`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function acceptOfferLetter(execution_id, project_id, signature_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/executions/${execution_id}/signatures/${signature_id}/accept_offer_letter`;
  const request = customHttp({
    url,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    data,
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function createSignature(member_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/members/${member_id}/signatures`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function updateSignature(member_id, signature_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/members/${member_id}/signatures/${signature_id}`;
  const request = customHttp({
    url,
    method: "patch",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchSignature(member_id) {
  const url = `${IH_OMS_API_URL}/office/api/v1/members/${member_id}/signatures/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function deallocateLead(execution_id, project_role_id, list_view_id, block_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/executions/${execution_id}/project_roles/${project_role_id}/list_views/${list_view_id}/blocks/${block_id}/leads/bulk_deallocate`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload:
            "Your request to deallocate leads has been taken successfully. Please refresh after sometime to see the deallocated leads or check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export const CommunicationTemplatesSearch = (project_id, role_id, data) => (dispatch) => {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_roles/${role_id}/communication_templates/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  const response = request
    .then((response) => {
      return { status: "successStatus", message: response };
    })
    .catch((error) => {
      handleLogoutError(error, dispatch);
      return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
    });
  return response;
};

export const BulkCommunications = (project_id, role_id, template_id, type, data) => (dispatch) => {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/project_roles/${role_id}/communication_templates/${template_id}/executions/bulk_${type}`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  const response = request
    .then((response) => {
      dispatch({
        type: GLOBAL_INFO,
        payload: "Your request for Bulk Communication has been taken successfully. Check reports section for the status of your request.",
      });
      return { status: "successStatus", message: response };
    })
    .catch((error) => {
      handleLogoutError(error, dispatch);
      return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
    });
  return response;
};

export const trackWorkSearch = (project_id, role_id) => (dispatch) => {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_roles/${role_id}/track_work_views/search`;
  const request = customHttp({
    url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  const response = request
    .then((response) => {
      return { status: "successStatus", message: response };
    })
    .catch((error) => {
      handleLogoutError(error, dispatch);
      return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
    });
  return response;
};

export const executionsAdvancedAnalyze = (project_id, role_id, track_work_id, data) => (dispatch) => {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/project_roles/${role_id}/track_work_views/${track_work_id}/leads/advance_analyze`;
  const request = customHttp({
    url,
    data,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  const response = request
    .then((response) => {
      return { status: "successStatus", message: response };
    })
    .catch((error) => {
      handleLogoutError(error, dispatch);
      return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
    });
  return response;
};

export const sendCertificate = (project_id, role_id, execution_id, certificate_params) => (dispatch) => {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/project_roles/${role_id}/executions/${execution_id}/send_certificate`;
  const request = customHttp({
    url,
    data: { certificate_params },
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  const response = request
    .then((response) => {
      return { status: "successStatus", message: response };
    })
    .catch((error) => {
      handleLogoutError(error, dispatch);
      return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
    });
  return response;
};

export const bulkExecutionStatusUpdate = (project_id, role_id, data) => (dispatch) => {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/project_roles/${role_id}/executions/bulk_status_update`;
  const request = customHttp({
    url,
    data,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  const response = request
    .then((response) => {
      dispatch({
        type: GLOBAL_INFO,
        payload:
          "Your request to change status of the executions has been taken successfully. Please refresh after sometime to see the updated executions or check reports section for the status of your request.",
      });
      return { status: "successStatus", message: response };
    })
    .catch((error) => {
      handleLogoutError(error, dispatch);
      return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
    });
  return response;
};

export const phoneBridgeCall = (execution_id, lead_id, data) => (dispatch) => {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/leads/${lead_id}/phone_bridge`;
  const request = customHttp({
    url,
    data,
    method: "post",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  const response = request
    .then((response) => {
      dispatch({ type: GLOBAL_INFO, payload: "Call Initiated" });
      return { status: "successStatus", message: response };
    })
    .catch((error) => {
      handleLogoutError(error, dispatch);
      return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
    });
  return response;
};
