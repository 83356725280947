import { IH_OMS_API_URL, GLOBAL_ERROR, ERROR_500, GLOBAL_INFO } from "../constant";
import { handleLogoutError } from "./action_common";
import customHttp from "../custom_http";

export function searchAllocationGroups(project_id, role_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/project_roles/${role_id}/allocation_groups/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function searchAllocationRules(project_id, allocation_group_id, data) {
  const url = `${IH_OMS_API_URL}/projects/api/v1/projects/${project_id}/allocation_groups/${allocation_group_id}/allocation_rules/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function allLeadsSearch(execution_id, data = {}) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/leads/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function manualPushAllocation(project_id, role_id, allocation_group_id, allocation_rule_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/project_roles/${role_id}/allocation_groups/${allocation_group_id}/allocation_rules/${allocation_rule_id}/manual_push_allocation`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload: "Your request has been taken successfully. Please check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function autoPushAllocation(project_id, role_id, allocation_group_id, allocation_rule_id, data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/workforce/projects/${project_id}/project_roles/${role_id}/allocation_groups/${allocation_group_id}/allocation_rules/${allocation_rule_id}/auto_push_allocation`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload: "Your request has been taken successfully. Please check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export function manualBulkAllocation(execution_id, project_role_id, data = {}) {
  const url = `${IH_OMS_API_URL}/office/api/v1/executions/${execution_id}/project_roles/${project_role_id}/leads/bulk_allocate`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: GLOBAL_INFO,
          payload: "Your request to allocate leads has been taken successfully. Please check reports section for the status of your request.",
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}
