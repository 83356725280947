import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Router, Route, Switch } from "react-router-dom";
import Cookies from "universal-cookie";
import { validateToken, checkValidAccess, logout, getProfile, setSaasPermissions, setSaasRole } from "./actions";
/* Shared */
import { windowWidth, isEmpty } from "./components/shared/custom_html_elements/utils";
import { LOGIN, DEVICE_TYPE, GLOBAL_ERROR, GLOBAL_SUCCESS, GLOBAL_INFO, MAINTENANCE_ERROR, FROM_URL, DOMAIN, HIDE_OFFLINE, MY_PROFILE, USE_FB_PIXEL } from "./constant";
import history from "./history";
import NotFound from "./components/shared/static/not_found/not_found";
import "./styles/app.scss";
import OfflineObserver from "./components/shared/offline";

/* Admin Screens */
const ScreensHome = lazy(() => import("./screens/home/"));
// const ScreensExecution = lazy(() => import("./screens/executions/show"));
const ChooseAccess = lazy(() => import("./screens/choose_access"));
const ScreensExecutions = lazy(() => import("./screens/executions"));
const ScreensLeads = lazy(() => import("./screens/executions/leads/"));
const ScreensLeadsShow = lazy(() => import("./screens/executions/leads/show"));
// const ScreensWorkforce = lazy(() => import("./screens/executions/workforce"));
const ScreensUnauthenticatedLeadsNew = lazy(() => import("./screens/executions/leads/unauthenticated/new"));
const ScreensUnauthenticatedLeadsEdit = lazy(() => import("./screens/executions/leads/unauthenticated/edit"));
const WorkforceDashboard = lazy(() => import("./screens/executions/workforceDashboard"));
const ApplicationDetails = lazy(() => import("./screens/executions/applicationDetails"));
import Loader from "./components/shared/custom_html_elements/loader";
import RedirectScreen from "./components/shared/static/redirect";
import { layoutWrapper } from "./wrapper";

const redirects = [];

const routes = [
  {
    path: "/",
    component: layoutWrapper(ScreensHome, false, false, false),
    exact: true,
  },
  {
    path: "/choose_access/",
    component: layoutWrapper(ChooseAccess, true, true, false, false, false),
    exact: true,
    protected: true,
  },
  {
    path: "/:access_type/executions/",
    component: layoutWrapper(ScreensExecutions, true, false, false, true, false),
    exact: true,
    protected: true,
  },
  {
    path: "/:access_type/projects/:project_id/executions/:execution_id/roles/:role_name/leads",
    component: layoutWrapper(ScreensLeads, true, false, false, true, true, "ScreensLeads"),
    exact: true,
    protected: true,
  },
  {
    path: "/:access_type/projects/:project_id/executions/:execution_id/roles/:role_name/dashboard",
    component: layoutWrapper(WorkforceDashboard, true, false, false, false, true),
    exact: true,
    protected: true,
  },
  {
    path: "/:access_type/projects/:project_id/executions/:execution_id/roles/:role_name/application-details",
    component: layoutWrapper(ApplicationDetails, true, false, true, false, false),
    exact: true,
    protected: true,
  },
  {
    path: "/:access_type/executions/:execution_id/roles/:role_id/projects/:project_id/leads/:lead_id/status/:status",
    component: layoutWrapper(ScreensLeadsShow, true, true, false),
    exact: true,
    protected: true,
  },
  {
    path: "/projects/:project_id/project_roles/:role_id/data_views/:data_view_id/leads/new",
    component: layoutWrapper(ScreensUnauthenticatedLeadsNew, false, false, true, false, false, "ScreensLeads"),
    exact: true,
    protected: false,
  },
  {
    path: "/projects/:project_id/project_roles/:role_id/data_views/:data_view_id/leads/:lead_id",
    component: layoutWrapper(ScreensUnauthenticatedLeadsEdit, false, false, true, false, false, "ScreensLeads"),
    exact: true,
    protected: false,
  },
  {
    path: "*",
    component: layoutWrapper(NotFound),
    exact: true,
  },
];

// All logic for org, login and valid access
const readCookies = () => {
  const cookies = new Cookies();
  let access_token = cookies.get("currentUser", { path: "/", domain: `${DOMAIN}` });
  return access_token ? true : false;
};

export const RouteWithSubRoutes = (route) => <Route exact={route.exact} path={route.path} render={(props) => <route.component {...props} />} />;

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (readCookies() ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)} />
);

const getRoutes = (customRoutes = routes) => customRoutes.map((route, i) => (route.protected ? <ProtectedRoute key={i} {...route} /> : <RouteWithSubRoutes key={i} {...route} />));

const getRedirects = (customRedirects = redirects) => customRedirects.map((redirect, i) => <Redirect from={redirect.from} to={redirect.to} key={i} />);

class Parent extends Component {
  constructor(props) {
    super(props);
  }

  removeGlobalError = () => {
    setTimeout(() => {
      this.props.deleteGlobalError();
    }, 30000);
  };

  renderGlobalError = () => {
    if (this.props.globalError) {
      return (
        <div className="parent_global_error">
          {this.props.globalError}
          <span className="delete-error" onClick={() => this.props.deleteGlobalError()}>
            <i className="fa fa-times"></i>
          </span>
        </div>
      );
    }
    return null;
  };

  removeGlobalSuccess = () => {
    setTimeout(() => {
      this.props.deleteGlobalSuccess();
    }, 10000);
  };

  renderGlobalSuccess = () => {
    if (this.props.globalSuccess) {
      return (
        <div className="parent_global_success">
          {this.props.globalSuccess}
          <span className="delete-error" onClick={() => this.props.deleteGlobalSuccess()}>
            <i className="fa fa-times"></i>
          </span>
        </div>
      );
    }
    return null;
  };

  removeGlobalInfo = () => {
    setTimeout(() => {
      this.props.deleteGlobalInfo();
    }, 60000);
  };

  renderGlobalInfo = () => {
    if (this.props.globalInfo) {
      return (
        <div className="parent_global_info">
          {this.props.globalInfo}
          <span className="delete-error" onClick={() => this.props.deleteGlobalInfo()}>
            <i className="fa fa-times"></i>
          </span>
        </div>
      );
    }
    return null;
  };

  removeOffline = () => {
    setTimeout(() => {
      this.props.hideOffline();
    }, 60000);
  };

  renderOffline = () => {
    if (!this.props.offline.show) {
      return <div></div>;
    }
    if (this.props.offline.show) {
      return (
        <div className="parent_global_error">
          {this.props.offline.message}
          <span className="delete-error" onClick={() => this.props.hideOffline()}>
            <i className="fa fa-times"></i>
          </span>
        </div>
      );
    }
    return null;
  };

  renderMaintenanceError = () => {
    if (this.props.maintenanceError) {
      return (
        <div className="parent_maintenance_error">
          <div className="maintenance_error_container">
            <h4>{this.props.maintenanceError}</h4>
          </div>
        </div>
      );
    }

    return null;
  };

  scrollToTop = () => {
    this.messageTop.scrollIntoView({ behavior: "smooth" });
  };

  callLogout = () => {
    this.props.checkValidAccess(true);
    this.props.logout().then(() => history.push("/"));
  };

  setCurrentUserAndRedirect(fromUrl) {
    console.log("Login details found");
    this.props.validateToken().then((returnValue) => {
      if (returnValue.status === "successStatus") {
        this.props.getProfile(returnValue.message.data.data.user.id);
        this.validateAccessAndRedirect(returnValue.message.data.data, fromUrl);
        return true;
      } else {
        return false;
      }
    });
  }

  validateAccessAndRedirect(user_data, fromUrl) {
    const currentUser = user_data.user;
    const headers = user_data.headers;
    // Checking valid access here as user is logged in.
    let that = this;
    let has_valid_access = false;
    if (!isEmpty(currentUser) && currentUser.roles.includes("saas-client")) {
      const saas_allowed_permissions = ["can_view_projects", "can_view_own_projects"];
      const saas_org_id = headers["saas-org-id"];
      const membership = currentUser.memberships.find((elm) => elm.domain === saas_org_id) || {};
      const membership_roles = membership.roles || {};
      const saas_user_roles = Object.keys(membership_roles).filter((elm) =>
        ["saas-account-owner", "saas-account-admin", "saas-project-owner", "saas-senior-management-team"].includes(elm)
      );
      const saas_user_permissions = saas_user_roles.map((role) => membership_roles[role]).flat();
      const filtered_saas_user_permissions = [...new Set(saas_user_permissions)];
      if (filtered_saas_user_permissions.length > 0 && filtered_saas_user_permissions.some((role) => saas_allowed_permissions.includes(role))) {
        this.props.setSaasRole(saas_user_roles[0]);
        this.props.setSaasPermissions(filtered_saas_user_permissions);
        has_valid_access = true;
      }
    } else if (!isEmpty(currentUser) && currentUser.roles.some((role) => ["int-workforce", "ext-workforce", "int-manager", "int-po"].includes(role))) {
      has_valid_access = true;
    }
    that.props.checkValidAccess(has_valid_access);
    if (fromUrl) {
      localStorage.removeItem("fromUrl");
      history.push(fromUrl);
    }
  }

  // End

  UNSAFE_componentWillMount() {
    const cookies = new Cookies();
    const isCookiesSet = readCookies();
    const fromUrl = localStorage.getItem("fromUrl");
    // const authData = localStorage.getItem("auth_data");
    let profile_data = localStorage.getItem("profile_data");
    let currentUser = cookies.get("currentUser");
    if (currentUser && currentUser !== "undefined") {
      if (!isCookiesSet) {
        localStorage.removeItem("auth_data");
        localStorage.removeItem("profile_data");
        return;
      }
      currentUser = currentUser?.data?.data;
      profile_data = JSON.parse(profile_data);
      if (!profile_data) {
        this.props.getProfile(currentUser?.user.id);
      } else {
        this.props.setProfileData(profile_data);
      }
      this.props.setAuthData(currentUser?.user);
      this.validateAccessAndRedirect(currentUser, fromUrl);
    } else if (isCookiesSet) {
      this.setCurrentUserAndRedirect(fromUrl);
    } else {
      this.props.checkValidAccess(true);
    }
    // Checking again with different variable name since it is getting set recursively with fromUrl because of the variable assigned at the top.
    const checkFromUrl = localStorage.getItem("fromUrl");
    if (checkFromUrl && checkFromUrl !== "undefined") {
      this.props.setFromUrl(checkFromUrl);
    }
    this.props.setDeviceType(windowWidth());
  }

  componentDidMount() {
    // if(process.env.NODE_ENV === 'production'){
    //   const script = document.createElement("script");
    //
    //   script.src = "/public/new_relic.js";
    //   script.async = true;
    //
    //   document.head.appendChild(script);
    // }
    if (process.env.REACT_APP_SERVE === "production") {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "k7onxdtjcg");`;
      document.head.appendChild(script);
    }

    this.unlisten = history.listen((location, action) => {
      window.scrollTo(0, 0);
      this.props.deleteGlobalError();
    });
    if (this.props.globalError !== null) {
      this.removeGlobalError();
    }
    if (this.props.globalSuccess !== null) {
      this.removeGlobalSuccess();
    }
    if (this.props.globalInfo !== null) {
      this.removeGlobalInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEmpty(this.props.globalError)) {
      this.removeGlobalError();
    }
    if (!isEmpty(this.props.globalSuccess)) {
      this.removeGlobalSuccess();
    }
    if (!isEmpty(this.props.globalInfo)) {
      this.removeGlobalInfo();
    }
    if (!isEmpty(this.props.offline.message)) {
      this.removeOffline();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { currentUser } = this.props;

    if (this.props.hasValidAccess) {
      if (
        (currentUser?.roles || []).some((role) => ["int-manager", "int-po"].includes(role)) &&
        !(currentUser?.roles || []).some((role) => ["int-workforce", "ext-workforce"].includes(role))
      ) {
        return <RedirectScreen />;
      }
      return (
        <Router history={history}>
          <div className="parent-container">
            {/* <Offline>
              <span className="no-internet-span">You are offline. Please check your internet connection.</span>
            </Offline> */}
            <OfflineObserver />
            <div
              style={{ marginTop: "0px", float: "left", clear: "both" }}
              ref={(el) => {
                this.messageTop = el;
              }}
            />
            {this.renderOffline()}
            {this.renderGlobalError()}
            {this.renderGlobalSuccess()}
            {this.renderGlobalInfo()}
            {this.renderMaintenanceError()}
            <Suspense fallback={<Loader />}>
              <Switch>
                {getRedirects()}
                {getRoutes()}
              </Switch>
            </Suspense>
          </div>
        </Router>
      );
    }
    return (
      <div>
        {this.renderGlobalError()}
        {this.renderGlobalSuccess()}
        {this.renderGlobalInfo()}
        <div className="text-center">
          <img src="https://awign-production.s3.ap-south-1.amazonaws.com/ss-configure/ic_forbidden.png" />
          <h2 className="mt-1">Page Not Accessible. Please contact Awign Support to gain access to this platform.</h2>
          <a className="text-primary" onClick={() => this.callLogout()}>
            Logout
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    globalError: state.error.globalError,
    globalSuccess: state.error.globalSuccess,
    globalInfo: state.error.globalInfo,
    maintenanceError: state.error.maintenanceError,
    hasValidAccess: state.common.hasValidAccess,
    offline: state.error.offline,
    currentUser: state.login.currentUser,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setAuthData: (data) => dispatch({ type: LOGIN, payload: data }),
  setFromUrl: (data) => dispatch({ type: FROM_URL, payload: data }),
  setDeviceType: (deviceType) => dispatch({ type: DEVICE_TYPE, payload: deviceType }),
  deleteGlobalError: () => dispatch({ type: GLOBAL_ERROR, payload: "" }),
  deleteGlobalSuccess: () => dispatch({ type: GLOBAL_SUCCESS, payload: "" }),
  deleteGlobalInfo: () => dispatch({ type: GLOBAL_INFO, payload: "" }),
  hideOffline: () => dispatch({ type: HIDE_OFFLINE, payload: null }),
  validateToken: () => dispatch(validateToken()),
  checkValidAccess: (data) => dispatch(checkValidAccess(data)),
  logout: () => dispatch(logout()),
  setMaintenanceError: () =>
    dispatch({
      type: MAINTENANCE_ERROR,
      payload: "We do not support this browser at the moment. We strongly recommend using Google Chrome browser",
    }),
  getProfile: (data) => dispatch(getProfile(data)),
  setProfileData: (data) => dispatch({ type: MY_PROFILE, payload: data }),
  setSaasRole: (value) => dispatch(setSaasRole(value)),
  setSaasPermissions: (value) => dispatch(setSaasPermissions(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Parent);
