import axios from "axios";
import Cookies from "universal-cookie";
import { DOMAIN, SECURE } from "./constant";
import { uuidv4 } from "./components/shared/custom_html_elements/utils";

const curUrl = DOMAIN;

const customHttp = axios.create({ withCredentials: false });

const cookies = new Cookies();
const currentUser = cookies.get("currentUser");
const saas_org_id = currentUser?.data?.data?.headers["saas-org-id"];

customHttp.interceptors.request.use(
  (config) => {
    if (cookies.get("access-token")) {
      config.headers["access-token"] = cookies.get("access-token");
      config.headers.uid = cookies.get("uid");
      config.headers.client = cookies.get("client");
      config.headers["saas-org-id"] = saas_org_id || "nil";
    }
    config.headers["caller_id"] = "office-ui";
    config.headers["X-Request-Id"] = `office_ui_${uuidv4()}`;
    config.headers["X_CLIENT_ID"] = "office_ui";
    return config;
  },
  (error) => Promise.reject(error)
);

customHttp.interceptors.response.use(
  (response) => {
    if (response.config.headers["access-token"]) {
      const { uid, client } = response.config.headers;
      if (currentUser) cookies.set("currentUser", currentUser, { path: "/", domain: `${curUrl}`, maxAge: 172800, secure: SECURE });
      cookies.set("access-token", `${response.config.headers["access-token"]}`, { path: "/", domain: `${curUrl}`, maxAge: 172800, secure: SECURE });
      cookies.set("uid", `${uid}`, { path: "/", domain: `${curUrl}`, maxAge: 172800, secure: SECURE });
      cookies.set("client", `${client}`, { path: "/", domain: `${curUrl}`, maxAge: 172800, secure: SECURE });
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default customHttp;
