import moment from "moment";
import { capitalizeFirstLetter } from "../../custom_html_elements/utils";

export const handleNotifClicks = (click_action, action_data) => {
  switch (click_action) {
    case "OPEN_LEAD_LIST":
      return {
        link: `/workforce/projects/${action_data?.project_id}/executions/${action_data?.execution_id}/roles/${action_data?.project_role_uid}/leads?status=${action_data?.lead_status}`,
        platform: "same",
      };

    default:
      return null;
  }
};

const getMsDifference = (date) => new Date() - new Date(date);
const getHrDiff = (date) => Math.floor(getMsDifference(date) / 1000 / 60 / 60);
const getDaysDiff = (date) => Math.floor(getHrDiff(date) / 24);
const getHoursDifference = (date) =>
  Math.floor(getMsDifference(date) / 1000 / 60 / 60) >= 2
    ? `${Math.floor(getMsDifference(date) / 1000 / 60 / 60)} hours ago`
    : getMsDifference(date) / 1000 / 60 / 60 < 1
    ? `${Math.floor(getMsDifference(date) / 1000 / 60)} minutes ago`
    : "an hour ago";
const getDaysDifference = (date) => (Math.floor(getHrDiff(date) / 24) >= 2 ? `${Math.floor(getHrDiff(date) / 24)} days ago` : "a day ago");
const getWeeksDifference = (date) => (Math.floor(getDaysDiff(date) / 7) >= 2 ? `${Math.floor(getDaysDiff(date) / 7)} weeks ago` : "a week ago");
export const getCurrentDateDifference = (date) => (getDaysDiff(date) >= 7 ? getWeeksDifference(date) : getHrDiff(date) >= 24 ? getDaysDifference(date) : getHoursDifference(date));

export const getDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};
