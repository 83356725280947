import { ATTENDANCE, EXECUTION } from "../constant";

const INITIAL_STATE = {};

export default function AttendanceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ATTENDANCE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
