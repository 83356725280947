import React from "react";
import { Modal } from "react-bootstrap";
import { getDate, handleNotifClicks } from "./constants";
import history from "../../../../history";

const NotifModal = ({ notification, closeModal, isMobile, handleBellIcon }) => {
  const handleButtonClick = () => {
    const redirectionLink = handleNotifClicks(notification?.click_action, notification?.action_data);
    if (redirectionLink) {
      redirectionLink.platform === "other" ? window.open(redirectionLink.link, "_blank") : history.push(redirectionLink.link);
      handleBellIcon();
    }
    closeModal();
  };

  return (
    <Modal show={true} backdrop="static" onHide={closeModal} id="notif-modal" dialogClassName={isMobile() && "notif_mobile_modal"}>
      <div className="px-4 pt-4 pb-5" data-notificationstab="true">
        <div className="d-flex justify-content-end" data-notificationstab="true">
          <p className="font-24 bold mb-0 pointer" onClick={closeModal} data-notificationstab="true">
            &times;
          </p>
        </div>
        <div className="mb-4" data-notificationstab="true">
          {notification?.image ? (
            <div data-notificationstab="true">
              <img data-notificationstab="true" src={notification.image} alt="image" />
            </div>
          ) : (
            <React.Fragment>
              <p data-notificationstab="true" className="font-16 black semi-bold ">
                {notification?.title}
              </p>
              <p data-notificationstab="true" className="gray font-12">
                {getDate(notification?.created_at)}
              </p>
              <p data-notificationstab="true" className="font-14" style={{ color: "#3c4858" }}>
                {notification?.notification_text}
              </p>
            </React.Fragment>
          )}
        </div>
        <button className="btn btn-wb-primary py-2 bold font-14 w-100" data-notificationstab="true" onClick={handleButtonClick}>
          Okay
        </button>
      </div>
    </Modal>
  );
};

export default NotifModal;
