export const GLOBAL_SUCCESS = "GLOBAL_SUCCESS";
export const GLOBAL_INFO = "GLOBAL_INFO";
export const LOGIN = "LOGIN";
export const DEVICE_TYPE = "DEVICE_TYPE";
export const USER_LOGIN = "USER_LOGIN";
export const SCREEN = "SCREEN";
export const LOGOUT = "LOGOUT";
export const SIGNING_IN = "SIGNING_IN";
export const SIGNING_OUT = "SIGNING_OUT";
export const REDIRECT = "REDIRECT";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_OUT_ERROR = "SIGN_OUT_ERROR";
export const FORGOT_ERROR = "FORGOT_ERROR";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const MAINTENANCE_ERROR = "MAINTENANCE_ERROR";
export const RESTRICTED_BROWSERS = ["UC Browser"];
export const ERROR_500 = "Something Went Wrong";
export const ERROR_404 = "Entry Not found";
export const SUCCESS_200 = "Success";
export const VALID_ACCESS = "VALID_ACCESS";
export const FROM_URL = "FROM_URL";
export const BREADCRUMBS = "BREADCRUMBS";
export const ORGANISATION = "ORGANISATION";
export const SIDEBAR_ACTIVE = "SIDEBAR_ACTIVE";
export const SIDEBAR_STATE = "SIDEBAR_STATE";
export const FAVICON = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/awign-favicon.ico";
export const LOGO_ICON_BLUE = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/logo-icon-blue.png";
export const LOGO_ICON_WHITE = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/logo-icon-white.png";
export const LOGO_NAME_BLUE = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/logo-name-blue.svg";
export const LOGO_NAME_WHITE = "https://s3.ap-south-1.amazonaws.com/awign-production/react-ui/logo-name-white.png";
export const PHONE_NUMBER = "9513661681";
export const PROJECT = "PROJECT";
export const EXECUTION = "EXECUTION";
export const MY_PROFILE = "MY_PROFILE";
export const VERSION = "0.1.3";
export const SHOW_OFFLINE = "SHOW_OFFLINE";
export const HIDE_OFFLINE = "HIDE_OFFLINE";
export const LOADING = "LOADING";
export const WORKFORCE_ANALYSE_EXECUTIONS = "WORKFORCE_ANALYSE_EXECUTIONS";
export const LEAD_ATTRIBUTES = "LEAD_ATTRIBUTES";
export const LEAD_FILTERS = "LEAD_FILTERS";
export const DATA_VIEWS = "DATA_VIEWS";
export const PAYOUT_GROUPS = "PAYOUT_GROUPS";
export const LEADS_COUNT = "LEADS_COUNT";
export const SIGNATURE_DETAILS = "SIGNATURE_DETAILS";
export const AADHAR_DETAILS = "AADHAR_DETAILS";
export const DL_DETAILS = "DL_DETAILS";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const SAAS_ROLE = "SAAS_ROLE";
export const SAAS_PERMISSIONS = "SAAS_PERMISSIONS";
export const ATTENDANCE = "ATTENDANCE";

export const PROJECTS_CONFIGURE_URL = process.env.REACT_APP_PROJECTS_CONFIGURE_URL;
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const SECURE = JSON.parse(process.env.REACT_APP_SECURE);
export const TRACK_ID = process.env.REACT_APP_TRACK_ID;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const ROOT_URL = process.env.REACT_APP_ROOT_URL;
export const AUTH_UI_URL = process.env.REACT_APP_AUTH_UI_URL;
export const CAS_API_URL = process.env.REACT_APP_CAS_API_URL;
export const BFF_API_URL = process.env.REACT_APP_BFF_API_URL;
export const IH_OMS_API_URL = process.env.REACT_APP_IH_OMS_API_URL;
export const WOS_API_URL = process.env.REACT_APP_WOS_API_URL;
export const DOCUMENTS_URL = process.env.REACT_APP_DOCUMENTS_URL;
export const LOGGY_URL = process.env.REACT_APP_LOGGY_URL;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const DIR_NAME = process.env.REACT_APP_DIR_NAME;
export const S3_REGION = process.env.REACT_APP_S3_REGION;
export const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
export const S3_SECRET_KEY = process.env.REACT_APP_S3_SECRET_KEY;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const FULFILLMENT_URL = process.env.REACT_APP_FULFILLMENT_URL;
export const OLD_WEBSITE_URL = process.env.REACT_APP_OLD_WEBSITE_URL;
export const UNSUBSCRIBE_PROJECT_ID = process.env.REACT_APP_UNSUBSCRIBE_PROJECT_ID;
export const UNSUBSCRIBE_ROLE_ID = process.env.REACT_APP_UNSUBSCRIBE_ROLE_ID;
export const USE_CLEVER_TAP = JSON.parse(process.env.REACT_APP_USE_CLEVER_TAP);
export const USE_FB_PIXEL = JSON.parse(process.env.REACT_APP_USE_FB_PIXEL);
export const CAPTURE_SENTRY_ERRORS = JSON.parse(process.env.REACT_APP_CAPTURE_SENTRY_ERRORS);
export const CORE_API_URL = process.env.REACT_APP_CORE_API_URL;
export const DOCUMENT_HELPER_API_URL = process.env.REACT_APP_DOCUMENT_HELPER_API_URL;
export const BUCKET_ORIGIN = process.env.REACT_APP_BUCKET_ORIGIN;
