import { MY_PROFILE } from "../constant";

const INITIAL_STATE = {
  my_profile: {},
};

export default function ProfileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MY_PROFILE:
      return { ...state, my_profile: action.payload };
    default:
      return state;
  }
}
