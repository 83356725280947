import { DEVICE_TYPE, SIDEBAR_ACTIVE, SIDEBAR_STATE, FROM_URL, BREADCRUMBS, VALID_ACCESS, LOADING, SAAS_ROLE, SAAS_PERMISSIONS, SCREEN } from "../constant";

const INITIAL_STATE = {
  deviceType: "Smartphone",
  fromUrl: "",
  breadcrumbs: [],
  hasValidAccess: true,
  sidebarActive: "",
  sidebarState: "collapsed",
  loading: false,
  saas_role: "",
  saas_permissions: [],
  screen: {}
};

export default function commonReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DEVICE_TYPE:
      return { ...state, deviceType: action.payload };
    case VALID_ACCESS:
      return { ...state, hasValidAccess: action.payload };
    case SIDEBAR_ACTIVE:
      return { ...state, sidebarActive: action.payload };
    case SIDEBAR_STATE:
      return { ...state, sidebarState: action.payload };
    case FROM_URL:
      localStorage.setItem("fromUrl", action.payload);
      return { ...state, fromUrl: action.payload };
    case BREADCRUMBS:
      return { ...state, breadcrumbs: action.payload };
    case LOADING:
      return { ...state, loading: action.payload };
    case SAAS_ROLE:
      return { ...state, saas_role: action.payload };
    case SAAS_PERMISSIONS:
      return { ...state, saas_permissions: action.payload };
    case SCREEN:
      return { ...state, screen: action.payload };
    default:
      return state;
  }
}
