import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { getProject } from "../../../actions";
import { isEmpty, stringDeparameterize } from "../custom_html_elements/utils";
import "./restriction.scss";

const RestrictedProject = ({ getProject, params, isMobile }) => {
  const [state, setState] = useState({ name: "", email: "" });
  useEffect(() => {
    if (params?.project_id && params?.role_name) {
      getProject(params.project_id).then((returnValue) => {
        if (returnValue?.status === "successStatus") {
          const project = returnValue.message.data.data.project;
          const lead_configurers = project.lead_configurers || [];
          const project_configurers = project.project_configurers || [];
          let owner = {};
          if (!isEmpty(lead_configurers)) {
            owner = lead_configurers.find((mem) => mem.configurer_type === "owner") || {};
          } else {
            owner = project_configurers.find((mem) => mem.configurer_type === "owner") || {};
          }
          setState({ name: owner.name, email: owner.email });
        }
      });
    }
  }, []);
  const { name, email } = state;
  return (
    <div className={`center-x-y ${isMobile ? "restricted-project-mobile" : "restricted-project"}`}>
      {isMobile ? (
        <img src="https://awign-production.s3.ap-south-1.amazonaws.com/projects-ui/restrict-illustration-mobile.webp" height={200} />
      ) : (
        <img src="https://awign-production.s3.ap-south-1.amazonaws.com/projects-ui/restrict-illustration.webp" height={400} />
      )}
      <h3 className="text-center black">You don’t have access to this project</h3>
      {email &&
        (params?.role_name ? (
          <p className="font-16 text-center black">
            You have not been added as a {stringDeparameterize(params.role_name)} for this project. <br />
            To gain access, please contact <strong>{name}</strong>. <br />
            <span style={{ color: "#9e9e9e" }}>({email})</span>
          </p>
        ) : (
          <p className="font-16 black text-center">
            You don't have access to this project. <br /> To gain access, please contact <strong>{name}</strong>. <br /> <span style={{ color: "#9e9e9e" }}>({email})</span>
          </p>
        ))}
    </div>
  );
};

const mapStateToProps = ({ execution }) => ({ execution });

export default connect(mapStateToProps, { getProject })(RestrictedProject);
