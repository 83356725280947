import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import "./notifications.css";
import { getAllNotifications, handleNotificationSectionOpen } from "../../../actions";
import NotificationContent from "./NotificationContent";
import Mobile from "./Mobile";

export const Notifications = memo((props) => {
  const handleBellIcon = () => {
    props.handleNotificationSectionOpen(!props.notifications.isOpened);
  };

  const handleOutsideClick = (event) => {
    const notifModalId = document.getElementById("notif-modal");
    if (!props.isMobile() && event.target.getAttribute("data-notificationstab") !== "true" && props.notifications.isOpened && !notifModalId) {
      props.handleNotificationSectionOpen(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);

    return () => {
      document.addEventListener("click", handleOutsideClick, true);
    };
  });

  const fetchNotifications = () => {
    props.getAllNotifications().then((returnValue) => {
      if (returnValue?.status === "successStatus") {
        return true;
      } else {
        return false;
      }
    });
  };
  const notifications = props.notifications?.notifications;
  return (
    <div className={`d-flex`} data-notificationstab="true">
      <div className="pointer" onClick={handleBellIcon} style={{ margin: "auto", position: "relative" }} data-notificationstab="true">
        <img src="https://awign-production.s3.ap-south-1.amazonaws.com/office-ui/notification.svg" data-notificationstab="true" />
        {notifications?.total_unread_count > 0 && (
          <span className={`white notification_count`} data-notificationstab="true">
            {notifications?.total_unread_count > 9 ? "9+" : notifications?.total_unread_count}
          </span>
        )}
      </div>

      {props.notifications?.isOpened && !props.isMobile() && (
        <div className={`bg-white pt-5 pb-3 notifications_modal`} data-notificationstab="true">
          <NotificationContent handleBellIcon={handleBellIcon} notifications={notifications} isMobile={props.isMobile} />
        </div>
      )}
      {props.notifications?.isOpened && props.isMobile() && (
        <div className="mobile_wrapper">
          <div className={`bg-white pb-3 notifications_modal_mobile`}>
            <Mobile notifications={notifications} handleBellIcon={handleBellIcon} isMobile={props.isMobile} />
          </div>
        </div>
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = {
  getAllNotifications,
  handleNotificationSectionOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
