import {
  LOGOUT,
  GLOBAL_ERROR,
  FROM_URL,
  VALID_ACCESS,
  BREADCRUMBS,
  PROJECT,
  IH_OMS_API_URL,
  SIDEBAR_ACTIVE,
  SIDEBAR_STATE,
  GLOBAL_SUCCESS,
  GLOBAL_INFO,
  CAS_API_URL,
  MY_PROFILE,
  SHOW_OFFLINE,
  HIDE_OFFLINE,
  LOADING,
  WORKFORCE_ANALYSE,
  WORKFORCE_ANALYSE_EXECUTIONS,
  LEADS_COUNT,
  SIGNATURE_DETAILS,
  DL_DETAILS,
  AADHAR_DETAILS,
  SAAS_ROLE,
  SAAS_PERMISSIONS,
  SCREEN,
  EXECUTION,
  BUCKET_NAME,
  DOCUMENT_HELPER_API_URL,
  BUCKET_ORIGIN,
} from "../constant";
import history from "../history";
import customHttp from "../custom_http";

export function searchMembers(data) {
  const url = `${IH_OMS_API_URL}/office/api/v1/members/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          x;
          return { status: "errorStatus", message: error };
        }
      });
}

export function getProfile(id) {
  const url = `${CAS_API_URL}/profile/api/v1/users/${id}`;
  const request = customHttp({
    url,
    method: "get",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: MY_PROFILE,
          payload: response.data.data,
        });
        const profile_data = response.data.data;
        localStorage.setItem("profile_data", JSON.stringify(profile_data));
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error && error.response && error.response.data) {
          dispatch({
            type: GLOBAL_ERROR,
            payload: error.response.data.message,
          });
          return { status: "errorStatus", message: error };
        }
      });
}

export function collegeDetails(value) {
  const reqBody = { search_term: value };
  const url = `${CAS_API_URL}/profile/api/v1/colleges/search`;
  const headers = { "Content-Type": "application/json", caller_id: "office-ui" };
  const res = fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(reqBody),
  });
  return res;
}

export function handleLogoutError(error, dispatch) {
  if (error.response?.status === 401) {
    dispatch({ type: LOGOUT, payload: null });
    dispatch({ type: GLOBAL_ERROR, payload: "You have been logged out, Please SignIn again" });
    history.push("/");
    return;
  }
  if (error.response?.status === 403) {
    dispatch({ type: GLOBAL_ERROR, payload: "You are not authorized to access this, please try again" });
    return;
  }
  if (error.response?.status === 500) {
    dispatch({ type: GLOBAL_ERROR, payload: "Sorry, something went wrong." });
    return;
  }
  if (error?.response?.data) {
    dispatch({ type: GLOBAL_ERROR, payload: error.response.data.message });
    return;
  }
  return null;
}

export function clearProject() {
  return (dispatch) => {
    dispatch({ type: PROJECT, payload: {} });
    return { status: "successStatus", message: "" };
  };
}

export function setScreen(screen) {
  return (dispatch) => {
    dispatch({ type: SCREEN, payload: screen });
  };
}

export function setFromUrl(url) {
  console.log(url);
  return (dispatch) => {
    dispatch({ type: FROM_URL, payload: url });
  };
}

export function setGlobalError(message) {
  return (dispatch) => {
    dispatch({ type: GLOBAL_ERROR, payload: message });
  };
}

export function setGlobalSuccess(message) {
  return (dispatch) => {
    dispatch({ type: GLOBAL_SUCCESS, payload: message });
  };
}

export function setGlobalInfo(message) {
  return (dispatch) => {
    dispatch({ type: GLOBAL_INFO, payload: message });
  };
}

export function setSidebarActiveState(data) {
  return (dispatch) => {
    dispatch({ type: SIDEBAR_ACTIVE, payload: data });
  };
}

export function setSidebarState(data) {
  return (dispatch) => {
    dispatch({ type: SIDEBAR_STATE, payload: data });
  };
}

export function setBreadcrumbs(data) {
  return (dispatch) => {
    dispatch({ type: BREADCRUMBS, payload: data });
  };
}

export function checkValidAccess(data) {
  return (dispatch) => {
    dispatch({ type: VALID_ACCESS, payload: data });
  };
}

export function showOffline(type, msg, delay) {
  return (dispatch) => {
    dispatch({ type: SHOW_OFFLINE, payload: { type, msg, delay } });
  };
}

export function hideOffline() {
  return (dispatch) => {
    dispatch({ type: HIDE_OFFLINE, payload: null });
  };
}

export const setLoader = (value) => (dispatch) => {
  dispatch({ type: LOADING, payload: value });
};

export const setWorkforceAnalyseState =
  (new_executions_data = []) =>
  (dispatch, getState) => {
    const state = getState();
    const executions_state = state.workforce_analyse_state.executions;
    const new_response = { ...executions_state, executions: [...new_executions_data] };
    dispatch({ type: WORKFORCE_ANALYSE_EXECUTIONS, payload: new_response });
  };

export const setLeadsCount = (value) => (dispatch) => {
  dispatch({ type: LEADS_COUNT, payload: value });
};

export const setOfferLetterState = (value) => (dispatch) => {
  const Obj = { signature_details: SIGNATURE_DETAILS, aadhar_details: AADHAR_DETAILS, dl_details: DL_DETAILS, my_profile: MY_PROFILE };
  const keys = Object.keys(value);
  keys.forEach((elm) => dispatch({ type: Obj[elm], payload: value[elm] }));
};

export const setSaasRole = (value) => (dispatch) => {
  dispatch({ type: SAAS_ROLE, payload: value });
};

export const setSaasPermissions = (value) => (dispatch) => {
  dispatch({ type: SAAS_PERMISSIONS, payload: value });
};

export const setExecution = (value) => (dispatch) => {
  dispatch({ type: EXECUTION, payload: value });
};

export const getS3PresignedURL = async (payload) => {
  const url = `${DOCUMENT_HELPER_API_URL}/api/v1/aws/s3/presigned-url/put-object`;
  const request = await customHttp({
    url,
    method: "post",
    data: payload,
  });
  return request;
};

export const updateACL = async (payload) => {
  const url = `${DOCUMENT_HELPER_API_URL}/api/v1/aws/s3/presigned-url/update-acl`;
  const request = await customHttp({
    url,
    method: "post",
    data: payload,
  });
  return request;
};

export const uploadToS3 = (file, file_dir) =>
  new Promise((resolve, reject) => {
    const upload = async () => {
      const fileName = file.name?.replace(/[^a-zA-Z0-9.-]/g, "");
      const s3PreSignedURLPayload = { file_type: file.type, file_path: `${file_dir}/${Date.now().toString()}-${fileName}`, bucket: BUCKET_NAME };
      const s3PreSignedURLResponse = await getS3PresignedURL(s3PreSignedURLPayload);
      if (s3PreSignedURLResponse.status === 200) {
        const s3PreSignedURLJSON = s3PreSignedURLResponse.data;
        await fetch(s3PreSignedURLJSON.data, { method: "PUT", body: file, headers: { ContentType: file.type } });
        const updateACLPayload = { acl: "public-read", file_path: s3PreSignedURLPayload.file_path, bucket: BUCKET_NAME };
        const updateACLResponse = await updateACL(updateACLPayload);
        if (updateACLResponse.status === 200) {
          resolve({
            message: "file uploaded",
            url: `${BUCKET_ORIGIN}/${s3PreSignedURLPayload.file_path}`,
          });
        } else {
          reject(new Error("failed to upload"));
        }
      }
      reject(new Error("failed to upload"));
    };
    upload();
  });
