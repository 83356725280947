import { WORKFORCE_ANALYSE_EXECUTIONS, LEAD_ATTRIBUTES, LEAD_FILTERS, DATA_VIEWS, PAYOUT_GROUPS, LEADS_COUNT } from "../constant";

const INITIAL_STATE = {
  executions: {},
  lead_filters: {},
  lead_attributes: {},
  data_views: {},
  payout_groups: {},
  leads_count: 0,
};

export default function WorkforceAnalyseReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WORKFORCE_ANALYSE_EXECUTIONS:
      return { ...state, executions: action.payload };
    case LEAD_FILTERS:
      return { ...state, lead_filters: action.payload };
    case LEAD_ATTRIBUTES:
      return { ...state, lead_attributes: action.payload };
    case DATA_VIEWS:
      return { ...state, data_views: action.payload };
    case PAYOUT_GROUPS:
      return { ...state, payout_groups: action.payload };
    case LEADS_COUNT:
      return { ...state, leads_count: action.payload };
    default:
      return state;
  }
}
