import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import Parent from "./route";
import { init, setTags } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ErrorBoundary from "./ErrorBoundary";
import { CAPTURE_SENTRY_ERRORS, DOMAIN } from "./constant";
import Cookies from "universal-cookie";

const RELEASE = "0.1.0";
if (CAPTURE_SENTRY_ERRORS) {
  const cookies = new Cookies();
  const ENV = process.env.REACT_APP_SERVE === "staging" ? "stage" : "production";
  let user = cookies.get("currentUser", { path: "/", domain: `${DOMAIN}` });
  user = user?.data?.data?.user;
  const name = user ? user.name : "N/A";
  const email = user ? user.email : "N/A";
  const mobile_number = user ? user.mobile_number : "N/A";
  init({
    dsn: "https://1f99c5ca7d374eacad00dfb405bae946@o100966.ingest.sentry.io/5247185",
    integrations: [new BrowserTracing()],
    release: RELEASE,
    environment: ENV,
    ignoreErrors: [
      `Cannot read property 'getReadModeRender' of undefined`,
      `Cannot read property 'getReadModeConfig' of undefined`,
      `Cannot read property 'getReadModeExtract' of undefined`,
      "Failed to fetch",
      "ResizeObserver loop limit exceeded",
      "Network Error",
      "timeout of 0ms exceeded",
      "Illegal invocation",
      "tgetT is not defined",
      "Non-Error promise rejection captured with value: failure",
      "privateSpecialRepair is not defined",
      "Non-Error promise rejection captured with keys: data, status, success",
      "ResizeObserver loop completed with undelivered notifications.",
      "Unexpected token '<'",
    ],
  });
  setTags({ name, email, mobile_number });
}

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(reduxThunk)));

const renderAll = () => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <Parent />
      </Provider>
    </ErrorBoundary>,
    document.getElementById("app")
  );
};

renderAll();
