import { EXECUTION } from "../constant";

const INITIAL_STATE = {};

export default function ExecutionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EXECUTION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
