import React, { memo, useState } from "react";
import { connect } from "react-redux";
import "./notifications.css";
import SingleNotification from "./singleNotification";
import { bulkUpdateNotifications } from "../../../actions";
import { isEmpty } from "../custom_html_elements/utils";

export const NotificationContent = memo((props) => {
  console.log("notification props  :  ", props);
  const [showMoreActions, setShowMoreActions] = useState(false);
  const { notifications } = props;

  const handleShowMoreActions = () => {
    setShowMoreActions(!showMoreActions);
  };

  const handleBulkUpdate = () => {
    let notifIds = [];
    let countOfNotAcked = 0;
    notifications?.notifications.map((notif) => {
      notifIds.push(notif.id);
      notif.status !== "acked" && countOfNotAcked++;
    });
    const data = {
      conditions: [
        {
          id: {
            operator: "in",
            value: notifIds,
          },
        },
      ],
      notification: {
        status: "acked",
      },
    };
    if (countOfNotAcked > 0) {
      props.bulkUpdateNotifications(data).then((returnValue) => {
        if (returnValue?.status === "successStatue") return true;
        else return false;
      });
    }
    setShowMoreActions(false);
  };

  return (
    <React.Fragment>
      <div className={`center-y justify-content-between pb-4 px-5 header`} data-notificationstab="true">
        <div data-notificationstab="true">
          {!props.isMobile() && (
            <p data-notificationstab="true" className="bold black font-20 mb-0">
              Notifications
            </p>
          )}
          <p data-notificationstab="true" className="gray font-14 mb-0">
            {props.notifications?.total_unread_count || "0"} Unread notifications
          </p>
        </div>
        <div data-notificationstab="true" className="relative">
          <img
            data-notificationstab="true"
            style={{ transform: props.isMobile() ? "rotateZ(90deg)" : "" }}
            className="pointer"
            onClick={handleShowMoreActions}
            src="https://awign-production.s3.ap-south-1.amazonaws.com/office-ui/notification-edit.svg"
            alt="edit"
          />
          {showMoreActions && (
            <div data-notificationstab="true" className={`font-14 black all_read_div pointer`} onClick={handleBulkUpdate}>
              Mark All As Read
            </div>
          )}
        </div>
      </div>
      <div data-notificationstab="true" className={!props.isMobile() ? "desktop_content_scroll" : "mobile_content_scroll"}>
        {isEmpty(notifications?.notifications) ? (
          <div className="font-20 bold black px-5 pt-3">No notifications found</div>
        ) : (
          notifications?.notifications.map((item) => <SingleNotification handleBellIcon={props.handleBellIcon} isMobile={props.isMobile} key={item.id} notification={item} />)
        )}
      </div>
    </React.Fragment>
  );
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  bulkUpdateNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContent);
