import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Breadcrumbs extends Component {
  render() {
    const match = this.props.match;
    const workforceLeadsPage = match.path === "/:access_type/projects/:project_id/executions/:execution_id/roles/:role_name/leads" && match.params.access_type === "workforce";
    const { breadcrumbs } = this.props;
    if (this.props.currentUser) {
      if (workforceLeadsPage) {
        return (
          <div className="row mx-0">
            <div className="offset-md-1 col-md-10">
              <nav aria-label="breadcrumb" className="mt-5 pt-2">
                <ol className={`breadcrumb ${workforceLeadsPage ? "px-3" : ""}`}>
                  <img src="https://awign-production.s3.ap-south-1.amazonaws.com/office-ui/dark_breadcrumb.svg" className="mr-2" />
                  {breadcrumbs &&
                    breadcrumbs.length > 0 &&
                    breadcrumbs.map((item, index) => (
                      <li key={index} className="breadcrumb-item">
                        {item["to"] && <Link to={item["to"]}>{item["label"]}</Link>}
                        {!item["to"] && <span>{item["label"]}</span>}
                      </li>
                    ))}
                </ol>
              </nav>
            </div>
          </div>
        );
      }
      return (
        <nav aria-label="breadcrumb" className="mt-5 pt-2">
          <ol className="breadcrumb">
            <img src="https://awign-production.s3.ap-south-1.amazonaws.com/office-ui/dark_breadcrumb.svg" className="mr-2" />
            {breadcrumbs &&
              breadcrumbs.length > 0 &&
              breadcrumbs.map(function (item, index) {
                return (
                  <li key={index} className="breadcrumb-item">
                    {item["to"] && <Link to={item["to"]}>{item["label"]}</Link>}
                    {!item["to"] && <span>{item["label"]}</span>}
                  </li>
                );
              })}
          </ol>
        </nav>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.login.currentUser,
    deviceType: state.common.deviceType,
    breadcrumbs: state.common.breadcrumbs,
  };
}

export default connect(mapStateToProps, {})(Breadcrumbs);
