import { CORE_API_URL, ERROR_500, NOTIFICATIONS } from "../constant";
import customHttp from "../custom_http";
import { handleLogoutError } from "./action_common";

export const getAllNotifications = () => (dispatch, getState) => {
  const state = getState();
  const userId = state?.login?.currentUser?.id
  const data = {
    'conditions': [
      {
        'user_id': {
          'operator': 'eq',
          'value': userId,
        },
      },
    ],
    'sort_column': 'created_at',
    'sort_order': 'desc',
    'limit': 40,
  };
  const url = `${CORE_API_URL}api/v1/notifications/search`;
  const request = customHttp({
    url,
    method: "post",
    data,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
    return request
      .then((response) => {
        dispatch({
            type: NOTIFICATIONS,
            payload: response.data.data,
          });
        return { status: "successStatus", message: response }
    })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
      });
}

export const handleNotificationSectionOpen = (payload) => (dispatch) => {
    dispatch({type: `${NOTIFICATIONS}_isOpened`, payload: payload});
};

export function updateSingleNotification(data, notification_id) {
    const url = `${CORE_API_URL}api/v1/notifications/${notification_id}`;
    const request = customHttp({
      url,
      method: "patch",
      data,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return (dispatch) =>
      request
        .then((response) => {
          return { status: "successStatus", message: response }
      })
        .catch((error) => {
          handleLogoutError(error, dispatch);
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        });
}

export function bulkUpdateNotifications(data) {
    const url = `${CORE_API_URL}api/v1/notifications/bulk_update`;
    const request = customHttp({
      url,
      method: "patch",
      data,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    return (dispatch) =>
      request
        .then((response) => {
            dispatch({
                type: NOTIFICATIONS,
                payload: response.data.data,
              });
          return { status: "successStatus", message: response }
      })
        .catch((error) => {
          handleLogoutError(error, dispatch);
          return { status: "errorStatus", message: error?.response?.data || ERROR_500 };
        });
}
