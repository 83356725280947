import { LOGIN, CAS_API_URL, LOGOUT, GLOBAL_ERROR, BFF_API_URL } from "../constant";
import customHttp from "../custom_http";
import { handleLogoutError } from "./action_common";

export const logout = () => (dispatch, getState) => {
  const {
    login: { currentUser },
  } = getState();
  if (window.fcWidget) {
    window.fcWidget.destroy();
  }
  const user_id = currentUser.id;
  const url = `${BFF_API_URL}/user/${user_id}/logout`;
  const request = customHttp({
    url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  })
    .then(() => {
      dispatch({ type: LOGOUT, payload: null });
    })
    .catch((error) => {
      dispatch({ type: LOGOUT, payload: null });
      return error;
    });
  return request;
};

export function validateToken() {
  const url = `${CAS_API_URL}/central/auth/validate`;
  return (dispatch) =>
    customHttp({
      url,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        dispatch({
          type: LOGIN,
          payload: response.data.data.user,
        });
        return { status: "successStatus", message: response };
      })
      .catch((error) => {
        handleLogoutError(error, dispatch);
        dispatch({
          type: GLOBAL_ERROR,
          payload: "You are not logged in. Please login again.",
        });
        dispatch({
          type: LOGOUT,
          payload: null,
        });
        return { status: "errorStatus", message: error };
      });
}

export function forgot(data) {
  const url = `${CAS_API_URL}/auth/password`;

  return (dispatch) =>
    customHttp({
      url,
      method: "post",
      data,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
      .then((response) => ({ status: "successStatus", message: response }))
      .catch((error) => {
        handleLogoutError(error, dispatch);
        if (error.response) {
          return { status: "errorStatus", message: error };
        }

        dispatch({
          type: GLOBAL_ERROR,
          payload: "Something went wrong. Please refresh the page and try again.",
        });
        return { status: "errorStatus", message: error };
      });
}
