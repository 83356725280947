import React from "react";
import RestrictedProject from "./project";

export { RestrictedProject };

const RestrictedPage = () => {
  return (
    <div className="restricted">
      <i className="fa fa-3x fa-exclamation-circle" aria-hidden="true"></i>
      <h3>No Access</h3>
      <p>You don’t have access to this page</p>
    </div>
  );
};

export default RestrictedPage;
